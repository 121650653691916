import React, {Component, useState, useRef, useEffect} from 'react';
import './index.css';


function ProvinceDetail(props) {
    const {current} = props;
    const [layerClosing, setLayerClosing] = useState(false);
    const handleClose = () => {
        setLayerClosing(true);
    }
    const onLayerAnimEnd = () => {
        if (layerClosing) {
            setLayerClosing(false);
            props.handleClose();
        }
    }
    if (!current) return null;
    const AirportItem = ({item, disabled}) => (
        <div className={"normal-box thinner-box route-box" + (disabled ? '' : ' unlocked')} key={item.name}>
            <div className="cover-to-top">
                <div
                    className={"medium-title one-line text-shadow-more medium-title-" + (item.name.length)}>{item.name}</div>
                <div
                    className="very-small-text one-line text-shadow-more margin-top-2 num">{item.en}</div>
            </div>
            <div className={disabled ? 'airport-bg-disabled' : 'airport-bg'}
                 style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${item.id}.jpg-220')`}}></div>
            <div className="rotate-three-code num">{item.code}</div>
        </div>
    );
    return (
        <div className={"province-detail-layer " + (layerClosing ? 'closing' : '')} onAnimationEnd={onLayerAnimEnd}>
            <div className="province-detail-container">
                <div className="province-detail-title">{current.name}({current.unlocked}/{current.all})</div>
                <div className="boxs">
                    {current.unlockedAirports.map(item => <AirportItem item={item} key={item.name}/>)}
                    {current.lockedAirports.map(item => <AirportItem item={item} key={item.name} disabled={true}/>)}
                </div>
            </div>
            <div className="province-detail-layer-close-btn" onClick={handleClose}>返回详情页</div>
        </div>
    );
}

export default ProvinceDetail;
