import React, {Component, useState, useRef, useEffect} from 'react';
import './index.css';
import SwiperBox from "../Detail/SwiperBox";
import LatlngImg from "../../assets/latlng.png";
import TopRouteImg from "../../assets/toproute.png";
import TopAirportImg from "../../assets/topairport.png";
import TopCountryImg from "../../assets/topcountry.png";
import TopProvinceImg from "../../assets/topprovince.png";
import TopAirlineImg from "../../assets/topairline.png";
import LongestImg from "../../assets/longest.png";
import ShortestImg from "../../assets/shortest.png";

function Airport(props) {
    const {current, data} = props;
    const [layerClosing, setLayerClosing] = useState(false);
    const handleClose = () => {
        setLayerClosing(true);
    }
    const onLayerAnimEnd = () => {
        if (layerClosing) {
            setLayerClosing(false);
            props.handleClose();
        }
    }
    return (
        <div className={"airport-layer " + (layerClosing ? 'closing' : '')} onAnimationEnd={onLayerAnimEnd}>
            <div className="airport-container">
                <div className="airport-container-left" style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${current.id}.jpg-220')`}}>
                    <div className="airport-name one-line text-shadow-more">{current.name}</div>
                    <div className="airport-en one-line text-shadow-more">{current.en}</div>
                    <div className="airport-code num">{current.code}</div>
                    <div className="airport-city one-line text-shadow-more">{current.country === '中国' ? `${current.province || '-'} 中国` : current.city}</div>
                </div>
                <div className="airport-container-right">

                    <div className="airport-en one-line text-shadow-more">{data.historyMode || !data.year ? '历史' : `${data.year}年`}共乘坐{current.count}次</div>
                    <div className="airport-en one-line text-shadow-more">
                        {current.lng > 0 ? '东' : '西'}经{Math.abs(current.lng || 0).toFixed(1)}°,&nbsp;
                        {current.lat > 0 ? '北' : '南'}纬{Math.abs(current.lat || 0).toFixed(1)}°
                    </div>
                    <div className="airport-en one-line text-shadow-more">
                        {current.inuse ? '当前仍启用' : '当前已关闭'}
                    </div>
                    <div className="airport-en one-line text-shadow-more">

                    </div>
                </div>
            </div>
            <div className="close-btn" onClick={() => handleClose()}>收起</div>
        </div>
    );
}

export default Airport;
