export default [
    '#de3a3a',
    '#f15c35',
    '#e76a16',
    '#a86d38',
    '#f3ba23',
    '#adac00',
    '#9a8a0a',
    '#89a818',
    '#bfcd3e',
    '#71bb09',
    '#538909',
    '#843333',
    '#4aee45',
    '#0cbf4d',
    '#0bdbb1',
    '#21a881',
    '#128787',
    '#17b2cd',
    '#43b2ce',
    '#106e93',
    '#3890d4',
    '#0a5679',
    '#67a7d2',
    '#2260db',
    '#3e41de',
    '#654ece',
    '#3a0fd5',
    '#8f3cee',
    '#d625dc',
    '#e054d0',
    '#973a7c',
    '#b31e5f',
    '#335863',
    '#00c6db',
    '#587bff',
    '#6994ff',
    '#242e74',
]