import React, {Component, useState, useRef, useEffect, useMemo} from 'react';
import './index.css';
import SwiperItem from "../SwiperItem";
import Colors from '../../../const/color';

const WINDOW_PADDING_LEFT = 20;
const GAP_WIDTH = 10;


function SwiperBox(props) {
    const {
        items = [],
        header,
        render,
        renderKey,
        width,
        height,
        headerWidth,
    } = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentXOffset, setCurrentXOffset] = useState(0);
    const [translateXOffset, setTranslateXOffset] = useState(0);
    const [tooLeft, setTooLeft] = useState(true);
    const [lastXOffset, setLastXOffset] = useState(0);
    const [lastYOffset, setLastYOffset] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [colors, setColors] = useState([]);

    const PaddingLeft = GAP_WIDTH + (headerWidth || width);

    const handleClick = (e) => {
        if (isDragging && (currentXOffset - translateXOffset > 5 || currentXOffset - translateXOffset < -5)) {
            e.stopPropagation();
        }
    }

    const handleTouchStart = (e) => {
        let x, y;
        if (e.type === 'touchstart') {//移动端
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        } else if (e.type === 'mousedown') {//pc端
            x = e.clientX;
            y = e.clientY;
        }
        setIsDragging(true);
        setLastXOffset(x);
        setLastYOffset(y);
        setTranslateXOffset(translateXOffset);
        setTooLeft(translateXOffset <= 0);

    }
    const handleTouchMove = (e) => {
        if (!isDragging) {
            e.stopPropagation();
            return;
        }
        let x, y, offset;
        if (e.type === 'touchmove') {//移动端
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
            offset = 50;
        } else if (e.type === 'mousemove') {//pc端
            x = e.clientX;
            y = e.clientY;
            offset = 20;
        }
        const tx = x - lastXOffset;
        const ty = y - lastYOffset;
        if (ty > offset || ty < -offset) {
            setIsDragging(false);
            setTranslateXOffset(currentXOffset);
            setTooLeft(currentXOffset <= 0);
            e.stopPropagation();
            return;
        }
        const finalX = currentXOffset - tx;
        setTranslateXOffset(finalX);
        setTooLeft(finalX <= 0);
    }
    const handleTouchEnd = (e) => {
        if (!isDragging) {
            e.stopPropagation();
            return;
        }
        let x, y;
        if (e.type === 'touchend') {//移动端
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        } else if (e.type === 'mouseup') {//pc端
            x = e.clientX;
            y = e.clientY;
        }
        const tx = x - lastXOffset;
        const finalX = currentXOffset - tx;
        let newIndex = Math.round((finalX || 0) / (GAP_WIDTH + width));
        if (newIndex > items.length - 1) newIndex = items.length - 1;
        if (newIndex < 0) newIndex = 0;
        const newXOffset = (width + GAP_WIDTH) * newIndex;
        setCurrentIndex(newIndex);
        setTimeout(() => {
            setIsDragging(false);
            setCurrentXOffset(newXOffset);
            setTranslateXOffset(newXOffset);
            setTooLeft(newXOffset <= 0);
        }, 0);
    }

    useEffect(() => {
        if (!items || !items.length) return;
        const colors = [];
        for (let i = 0; i < items.length + 2; i++) {
            const rand = Math.floor(Math.random() * Colors.length);
            colors.push(Colors[rand]);
            setColors(colors);
        }
        setCurrentXOffset(0);
        setTranslateXOffset(0);
        setCurrentIndex(0);
        setIsDragging(false);
    }, [items]);

    const SwiperHeader = useMemo(() => {
        return (
            <div className="swiper-header"
                 style={{
                    opacity: isDragging && !tooLeft ? 0 : 1,
                     left: isDragging ? 20 : 0,
                 }}>
                <SwiperItem
                    key="header"
                    width={headerWidth || width}
                    height={height}
                    content={header}
                    colors={[colors[currentIndex], colors[currentIndex + 1]]}
                />
            </div>
        )
    }, [isDragging, header, colors, currentIndex, tooLeft]);

    const SwiperInner = useMemo(() => {
        return (
            <div className="swiper-box-inner">
                {(items || []).map((item, index) => {
                    const key = typeof renderKey === 'function' ? renderKey(item) : renderKey ? item[renderKey] : index;
                    if (!key || !item) return null;
                    return (
                        <SwiperItem
                            key={key}
                            data={item}
                            width={width}
                            height={height}
                            colors={[colors[index+1], colors[index+2]]}
                            render={(e) => render(e, index)}
                        />
                    );
                })}
            </div>
        )
    }, [items, colors]);

    if (!items || !items.length) return null;

    return (
        <div
            className="swiper-box-container"
            style={{
                height, left: isDragging ? 0 : WINDOW_PADDING_LEFT,
                borderTopLeftRadius: isDragging ? 0 : 5,
                borderBottomLeftRadius: isDragging ? 0 : 5}}
        >
            { SwiperHeader }
            <div
                className="swiper-box"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleTouchStart}
                onMouseMove={handleTouchMove}
                onMouseUp={handleTouchEnd}
                onMouseLeave={handleTouchEnd}
                onClickCapture={handleClick}
                style={{
                    paddingLeft: PaddingLeft + (isDragging ? WINDOW_PADDING_LEFT : 0),
                    transition: isDragging ? '' : 'left 0.3s',
                    left: -translateXOffset || 0,
                    width: items.length * (width + GAP_WIDTH) + (headerWidth || width) + WINDOW_PADDING_LEFT * 2,
                    height
                }}
            >
                { SwiperInner }
            </div>
        </div>);
}

export default SwiperBox;
