import React, {Component, useState, useRef, useEffect} from 'react';
import './index.css';
import indexBy from "index-array-by";
import SwiperBox from "./SwiperBox";


import MyInfoImg from "../../assets/myInfo.png";
import LatlngImg from "../../assets/latlng.png";
import P100Img from "../../assets/province100.png";
import P50Img from "../../assets/province50.png";
import P25Img from "../../assets/province25.png";
import P1Img from "../../assets/province1.png";
import P0Img from "../../assets/province0.png";
import TopCountryImg from "../../assets/topcountry.png";
import ProvinceDetail from "../ProvinceDetail";

function rad(d) {
    return d * Math.PI / 180.0;
}

function ChinaModeDetail(props) {
    const [layerClosing, setLayerClosing] = useState(false);
    const [info, setInfo] = useState(null);
    const [positionMost, setPositionMost] = useState("eastmost");
    const [currentProvince, setCurrentProvince] = useState(null);
    const handleClose = () => {
        setLayerClosing(true);
    }
    const handleAirportClick = (airport) => {
        if (!airport) return;
        handleClose();
        props.handleEnterAirport && props.handleEnterAirport(airport);
    }
    const onLayerAnimEnd = () => {
        if (layerClosing) {
            setLayerClosing(false);
            props.handleClose();
        }
    }
    useEffect(() => {
        const info = {};
        const {airports = [], flights = [], config = {}, fontface} = props.data;
        airports.forEach(e => {
            e.lat = +e.lat;
            e.lng = +e.lng;
        }); // 经纬度转下整数
        const byIata = indexBy(airports, 'id', false);
        Object.keys(byIata).forEach(e => {
            byIata[e].id = e
        });
        flights.forEach(flight => {
            if (byIata[flight.dep]) byIata[flight.dep].count = (byIata[flight.dep]?.count || 0) + 1;
            if (byIata[flight.arr]) byIata[flight.arr].count = (byIata[flight.arr]?.count || 0) + 1;
            if (flight.stop && byIata[flight.stop]) byIata[flight.stop].count = (byIata[flight.stop]?.count || 0) + 1;
        });
        let allAirports = Object.values(byIata).sort((a, b) => (b.count || 0) - (a.count || 0));
        // 按省统计
        const provinceObj = {};
        allAirports.forEach(airport => {
            if (airport.province && airport.inuse) {
                if (!provinceObj[airport.province]) {
                    provinceObj[airport.province] = {
                        all: 0,
                        unlocked: 0,
                        name: airport.province,
                        top: airport,
                        lockedAirports: [],
                        unlockedAirports: []
                    };
                }
                provinceObj[airport.province].all += 1;
                if (airport.count) {
                    provinceObj[airport.province].unlocked += 1;
                    provinceObj[airport.province].unlockedAirports.push(airport);
                } else {
                    provinceObj[airport.province].lockedAirports.push(airport);
                }
            }
        });
        const unlockedAirports = allAirports.filter(e => e.count);
        const provinces = Object.values(provinceObj);
        provinces.forEach(e => {
            e.rate = e.unlocked / e.all;
        })
        // 基础数据
        info.basicInfo = [
            {
                key: '打卡的运行中机场',
                count: allAirports.filter(e => e.inuse && e.count).length,
                all: allAirports.filter(e => e.inuse).length,
            },
            {
                key: '打卡的省份',
                count: provinces.filter(e => e.unlocked).length,
                all: provinces.length
            }
        ];
        if (unlockedAirports.length) {
            info.directionMost = [
                {key: '最东点', value: unlockedAirports.sort((a, b) => b.lng - a.lng)[0]},
                {key: '最南点', value: unlockedAirports.sort((a, b) => a.lat - b.lat)[0]},
                {key: '最西点', value: unlockedAirports.sort((a, b) => a.lng - b.lng)[0]},
                {key: '最北点', value: unlockedAirports.sort((a, b) => b.lat - a.lat)[0]}
            ];
            info.directionMost[0].content = `${info.directionMost[0].value.lng > 0 ? '东' : '西'}经${Math.abs(info.directionMost[0].value.lng || 0).toFixed(2)}°`
            info.directionMost[2].content = `${info.directionMost[2].value.lng > 0 ? '东' : '西'}经${Math.abs(info.directionMost[2].value.lng || 0).toFixed(2)}°`
            info.directionMost[1].content = `${info.directionMost[1].value.lat > 0 ? '北' : '南'}纬${Math.abs(info.directionMost[1].value.lat || 0).toFixed(2)}°`
            info.directionMost[3].content = `${info.directionMost[3].value.lat > 0 ? '北' : '南'}纬${Math.abs(info.directionMost[3].value.lat || 0).toFixed(2)}°`
        }
        info.provinceSummary = [
            {
                name: '打卡全部机场',
                list: provinces.filter(e => e.rate >= 1),
                pic: P100Img
            },
            {
                name: '打卡一半以上机场',
                list: provinces.filter(e => e.rate >= 0.5 && e.rate < 1),
                pic: P50Img
            },
            {
                name: '打卡1/4以上机场',
                list: provinces.filter(e => e.rate >= 0.25 && e.rate < 0.5),
                pic: P25Img
            },
            {
                name: '打卡过机场',
                list: provinces.filter(e => e.rate && e.rate < 0.25),
                pic: P1Img
            },
            {
                name: '未打卡过机场',
                list: provinces.filter(e => !e.rate),
                pic: P0Img
            }
        ];
        setInfo(info);
    }, [props.data]);
    useEffect(() => {
        const handleTouchMove = (event) => event.preventDefault();
        document.addEventListener('touchmove', handleTouchMove, {passive: false});
        return () => {
            document.removeEventListener('touchmove', handleTouchMove, {passive: false});
        }
    }, []);
    if (!info) return null;
    return (
        <>
            <div className={"detail-layer " + (layerClosing ? 'closing' : '')} onAnimationEnd={onLayerAnimEnd}>
                <div className="detail-container">
                    <div className="detail-container-inner">
                        <SwiperBox
                            key="basicInfo"
                            items={info.basicInfo || []}
                            width={100}
                            height={70}
                            headerWidth={80}
                            header={<img src={MyInfoImg}/>}
                            render={(item, index) => <div className="normal-box thinner-box route-box">
                                <div
                                    className="very-small-text one-line text-shadow-more num">{item.key}</div>
                                <div className="rank-right num rank-right-bottom basic-info left">{item.count}</div>
                                <div className="box-footer right num">/{item.all}</div>
                            </div>}
                            renderKey={e => e.key}
                        />
                        <SwiperBox
                            key="directionMost"
                            items={info.directionMost || []}
                            width={120}
                            height={70}
                            headerWidth={80}
                            header={<img src={LatlngImg}/>}
                            render={(item, index) => <div onClick={() => handleAirportClick(item.value)}
                                                          className="normal-box route-box">
                                <div className="cover-to-top">
                                    <div
                                        className={"medium-title one-line text-shadow-more medium-title-" + (item.value.name.length)}>{item.value.name}</div>
                                    <div className="very-small-text one-line text-shadow-more margin-top-2 num">
                                        {item.value.province || ''} / {item.value.en || ''}
                                    </div>
                                    <div className="very-small-text one-line text-shadow-more margin-top-15">
                                        {item.key} {item.content}
                                    </div>
                                </div>
                                <div className="airport-bg airport-bg-complete"
                                     style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${item.value.id}.jpg-220')`}}></div>
                                <div className="rotate-three-code num">{item.value.id}</div>
                            </div>}
                            renderKey={e => e.key}
                        />
                        {
                            info.provinceSummary.map((data => (
                                <SwiperBox
                                    key={data.name}
                                    items={data.list || []}
                                    width={120}
                                    height={70}
                                    headerWidth={80}
                                    header={
                                        [<img src={data.pic} key={0}/>,
                                            <div
                                                className="box-footer-label-left box-footer header-item num box-footer-label-new"
                                                key={1}>{(data.list || []).length}</div>]
                                    }
                                    render={(item, index) => <div className="normal-box route-box"
                                                                  onClick={() => setCurrentProvince(item)}>
                                        <div className="cover-to-top">
                                            <div
                                                className={"medium-title one-line text-shadow-more medium-title-" + (item.name.length)}>{item.name}</div>
                                            {item.unlocked ?
                                                <div className="very-small-text one-line text-shadow-more margin-top-2">
                                                    {/*{item.unlocked || 0}/{item.all || 0}*/}
                                                    最常打卡{item.top?.name || '未知'}
                                                </div> : null}
                                            <div
                                                className="box-footer-label box-footer text-shadow num">
                                                <b>{item.unlocked || 0}</b>/{item.all || 0}
                                            </div>
                                        </div>
                                        {item.top ?
                                            <div className="airport-bg airport-bg-complete"
                                                 style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${item.top.id}.jpg-220')`}}></div> : null}
                                    </div>}
                                    renderKey={e => e.name}
                                />
                            )))
                        }
                    </div>
                </div>
                <div className="close-btn" onClick={handleClose}>收起</div>
                <div className="detail-title">仅统计国内机场</div>
            </div>
            {currentProvince ? (
                <ProvinceDetail current={currentProvince} handleClose={() => setCurrentProvince(null)}/>
            ) : null}</>
    );
}

export default ChinaModeDetail;