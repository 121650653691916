import React, {Component, useState, useRef, useEffect} from 'react';
import './index.css';

function SwiperItem(props) {
    const { width, height, content, data, render, colors = []} = props;
    return (
        <div className="swiper-item" style={{
            width,
            height,
            background: colors.length && colors[0] && colors[1] && `linear-gradient(to right, ${colors[0]} 0%, ${colors[1]} 100%)`
        }}>
            {content}
            {render && render(data)}
        </div>
    )
}

export default SwiperItem;
