import React, {useState, useEffect, useRef, useMemo} from 'react';
import './index.css';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import World from '../../components/World';
import Loading from "../../components/Loading";
import Detail from "../../components/Detail";
import Airport from "../../components/Airport";
import china from "../../components/Detail/china";
import ChinaModeDetail from "../../components/Detail/china";
import {delay} from "../../utils/utils";

const currentYear = new Date().getFullYear();

const loadImage = (url, returnUrl = false) => {
    return new Promise((resolve) => {
        if (returnUrl) {
            resolve(url);
            return;
        }
        const img = new Image(); //创建一个Image对象，实现图片的预下载
        img.onload = function () {
            img.onload = null;
            resolve(returnUrl ? url : img);
        }
        img.src = url;
    })
}

const load3DObj = (url) => new Promise(resolve => {
    const loader = new GLTFLoader();
    loader.load(url, obj => resolve(obj.scene ? obj.scene : obj));
});

export const baseUrl = window.location && (window.location.href.indexOf('dustark.cn') !== -1) ? '' : 'https://www.starkdu.com';

const earthUrl = [
    `${baseUrl}/images/earth-night.jpeg`,
    `${baseUrl}/images/earth-blue.jpeg`,
    `${baseUrl}/images/earth-dark.jpg`,
    `${baseUrl}/images/earth2.jpeg`,
]

const Index = (props) => {
    const globeEl = useRef();
    const [loading, setLoading] = useState(true); // [{...}], an array of all the airports
    const [data, setData] = useState(null);
    const [extraData, setExtraData] = useState({});
    const [showDetail, setShowDetail] = useState(false);
    const [currentAirport, setCurrentAirport] = useState(null);
    const [outerWebview, setOuterWebview] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location && window.location.search);
        const chinaMode = !!params.get("china");
        const historyMode = chinaMode ? false : !!params.get("history");
        const year = chinaMode ? undefined : (params.get("year") || currentYear);
        const owner = params.get("owner") || 'dustark';
        const miniProgram = !!params.get("mini-program");
        const captureMode = !!params.get("capture");

        Promise.all([
            fetchData({chinaMode, historyMode, owner, year, data}),
            loadImage(earthUrl[0], true),
            loadImage(`${baseUrl}/images/earth-topology.png`, true),
            loadImage(`${baseUrl}/images/night-sky.png`, true)
        ]).then(([res, globeImage, bumbImage, bgImage]) => {
            console.log('res', res, data, chinaMode, historyMode)
            setData({
                ...res,
                globeImage,
                bumbImage,
                bgImage,
                owner,
                chinaMode,
                historyMode,
                year,
                mode: 0,
                miniProgram,
                captureMode
            });
            // setLoading(false);
            document.title = `飞哪 · ${owner ? (res.config && res.config.username) || '飞哪用户': 'DuStark'}的飞行足迹`
        });
    }, []);

    const fetchData = async ({chinaMode, historyMode, year, owner, data}) => {
        let summary = {};
        const userkey = owner || (data && data.owner) || '';
        // const fetchConfigPromise = async () => {
        //     if (!data || !data.config) {
        //         try {
        //             const res = await fetch(`${baseUrl}/user-client/user?userkey=${userkey}`).then(res => res.json());
        //             res.data && (summary.config = res.data);
        //         } catch (e) {
        //             console.error('请求个性化配置错误', e)
        //         }
        //     }
        // }
        const fetchChinaAirportsPromise = async () => {
            try {
                if (!chinaMode) return;
                const res = await fetch(`${baseUrl}/api/user-client/airport-cn`).then(res => res.json());
                res.data && (summary.airports = res.data);
            } catch (e) {
                console.error('请求国内机场列表错误', e)
            }
        }
        const fetchMainPromise = async () => {
            let types = [];
            if (owner !== undefined) {
                types = chinaMode ? ["FONT"] : ["AL", "AP", "AC", "FONT"];
            }
            if (chinaMode && (!data || !data.historyMode)) {
                types.push("F");
            }
            if (!chinaMode && (historyMode !== undefined || year !== undefined)) {
                types.push("F" + (historyMode || (historyMode === undefined && data.historyMode) ? '' : (year || data.year)));
                if (!data || data.chinaMode) {
                    types.push("AP");
                    data && data.chinaMode && !data.airlines && (types.push("AL"));
                    data && data.chinaMode && !data.aircrafts && (types.push("AC"));
                }
            }
            if (!types.length) return;
            try {
                const res = await fetch(`${baseUrl}/api/user-client/record?userkey=${userkey}&type=${types.join(',')}`).then(res => res.json());
                if (res.data) {
                    for (let item of res.data) {
                        if (!item || !item.type || !item.content) continue;
                        try {
                            switch (item.type) {
                                case "AC":
                                    summary.aircrafts = JSON.parse(item.content);
                                    break;
                                case "AL":
                                    summary.airlines = JSON.parse(item.content);
                                    break;
                                case "AP":
                                    summary.airports = JSON.parse(item.content);
                                    break;
                                case "FONT":
                                    summary.fontface = JSON.parse(item.content);
                                    break;
                                default:
                                    summary.flights = JSON.parse(item.content);
                                    break;
                            }
                        } catch (e) {
                            console.error('解析主接口错误', e)
                        }
                    }
                }
                if (res.user) {
                    summary.config = res.user;
                }
            } catch (e) {
                console.error('请求主接口错误', e)
            }
        }
        await Promise.all([fetchChinaAirportsPromise(), fetchMainPromise()]);
        return summary;
    }

    const handleWorldLoaded = async () => {
        if (!loading) return;
        setLoading(false);
        // 加载飞机
        await delay(4500);
        let planeObjUrl = `https://starkdu-1252533121.cos.ap-chengdu.myqcloud.com/320-${(data && data.config && data.config.coating) || 'default'}.gltf`;
        const planeObj = await load3DObj(planeObjUrl);
        setExtraData({...extraData, planeObj});
    }

    const switchHistoryMode = (historyMode) => {
        fetchData({historyMode, year: undefined, data}).then(res => {
            setData({...data, ...res, historyMode, year: undefined, chinaMode: false});
        })
    }

    const open2DVersion = () => {
        const base = window.location.origin.indexOf('local') !== -1 ? 'https://www.starkdu.com' : window.location.origin;
        if (data.chinaMode) {
            setOuterWebview(`${base}/airport?simple=1&owner=${data.owner}`);
        } else {
            setOuterWebview(`${base}/my?simple=1&owner=${data.owner}&year=${data.year}`);
        }
    }

    const switchChinaMode = (chinaMode) => {
        if (!chinaMode) {
            switchYear(currentYear);
            return;
        }
        fetchData({chinaMode, year: undefined, data}).then(res => {
            setData({...data, ...res, chinaMode, year: currentYear, historyMode: false});
        })
    }
    const switchMode = () => {
        const mode = (data.mode + 1) % earthUrl.length;
        loadImage(earthUrl[mode], true).then(globeImage => {
            setData({...data, mode, globeImage})
        });
    }
    const switchYear = (year) => {
        fetchData({historyMode: false, year, data}).then(res => {
            setData({...data, ...res, historyMode: false, year, chinaMode: false});
        })
    }

    const WorldComponent = useMemo(() => {
        if (!data)
            return null;
        return (
            <World data={data}
                   extraData={extraData}
                   loading={loading}
                   handleLoaded={handleWorldLoaded}
                   currentAirport={currentAirport}></World>
        )
    }, [data, currentAirport, loading, !!extraData.planeObj]);

    const FooterComponent = useMemo(() => {
        if (!data) return null;
        const hide = loading || !data || showDetail || currentAirport;
        return (
            <div>
                <div className={"footer " + (hide ? 'hide' : '')}>
                    {!data.flights || data.flights.length === 0 ? <div className="no-data">
                        没有航线记录...
                    </div> : null}
                    <div className="owner-name">
                        <div className={'switch-mode-' + data.mode} onClick={() => switchMode()}></div>
                        <div className="owner-name-top num">
                            {(data.config?.username) || (data.config?.userkey) || '未知用户'}的
                            {data.chinaMode ? '点亮中国进度' : data.historyMode ? '飞行记录' : `${data.year || currentYear}足迹`}</div>
                        <div className="owner-sayings">{data.config?.sayings || ''}</div>
                    </div>
                    <div className="footer-right">
                        <div className="footer-item" onClick={() => switchChinaMode(!data.chinaMode)}>
                            {!data.chinaMode ? '点亮中国进度' : ('切换至' + currentYear)}
                        </div>
                        {/*{data.historyMode ? null : <div className="footer-item" onClick={() => open2DVersion()}>*/}
                        {/*    二维地图版*/}
                        {/*</div>}*/}
                        {data.chinaMode ? null : !data.historyMode ?
                            <div className="footer-item" onClick={() => switchHistoryMode(true)}>
                                切换至历史记录
                            </div> : <div className="footer-item" onClick={() => switchYear(currentYear)}>
                                切换至{currentYear}
                            </div>}
                        {!data.chinaMode && !data.historyMode && (data.config && !data.config.hideOtherYears) ?
                            <div className="footer-item footer-item-flex">
                                {+data.year > 2015 ?
                                    <div onClick={() => switchYear(+data.year - 1)}>{'<'} {+data.year - 1}</div> : null}
                                {+data.year < currentYear ?
                                    <div onClick={() => switchYear(+data.year + 1)}>{+data.year + 1} {'>'}</div> : null}
                            </div> : null}
                        {data.flights && data.flights.length ?
                            <div className="footer-item footer-item-special" onClick={() => setShowDetail(true)}>
                                展开详情
                            </div> : null}
                    </div>
                </div>
                {!data.miniProgram ? <div className={"copyright"}>
                    飞哪©2021-23 <a href="https://weibo.com/ssdu">@dustark</a> . <a
                    href="https://beian.miit.gov.cn/">浙ICP备17035537号-2</a> . <a
                    href="https://www.starkdu.com/h5/"
                >前往飞哪</a>
                </div> : null}
            </div>
        )
    }, [loading, data, showDetail, currentAirport]);

    return <div>
        <div>
            {WorldComponent}
            {FooterComponent}
            {showDetail ? (data.chinaMode ? <ChinaModeDetail
                data={data}
                handleEnterAirport={(e) => setCurrentAirport(e)}
                handleClose={() => setShowDetail(false)}
            /> : <Detail
                data={data}
                handleEnterAirport={(e) => setCurrentAirport(e)}
                handleClose={() => setShowDetail(false)}
            />) : null}
            {currentAirport ? <Airport
                data={data}
                current={currentAirport}
                handleClose={() => setCurrentAirport(null)}
            /> : null}
        </div>
        <Loading loading={loading}></Loading>
    </div>;
};

export default Index;
