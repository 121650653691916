import React, {Component, useState, useRef, useEffect} from 'react';
import {withSize, sizeMe} from 'react-sizeme'
import * as THREE from 'three'
import Globe from 'react-globe.gl';
import * as d3 from 'd3';
import indexBy from 'index-array-by';
import planePng from '../../assets/plane.obj.jpg';
import menu1Png from '../../assets/menu1.png';
import menu2Png from '../../assets/menu2.png';
import menu3Png from '../../assets/menu3.png';
import {baseUrl} from "../../pages/Index";

const OPACITY = 0.6;
const CURVE_S = [0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 1.45, 1.55, 1.65, 1.75, 1.85, 1.95, 2.05, 2.15, 2.25, 2.35, 2.45, 2.55, 2.65, 2.75, 2.85, 2.9, 2.95, 3.0, 3.05, 3.1, 3.15, 3.2, 3.25, 3.3, 3.35, 3.4, 3.45, 3.5, 3.55, 3.6, 3.65, 3.7, 3.75, 3.8, 3.85, 3.9, 3.95, 4.0, 4.05, 4.1, 4.15];
const CURVE_N = [0.4, 0.2, 1.1, 1.3, 1.5, 1.7, 1.9, 2.1, 2.3, 2.5, 2.7, 2.9, 1.35, 1.25, 1.15, 1.05, 0.95, 0.85, 0.75, 0.65, 0.55, 0.45, 0.35, 0.25, 0.15, 0.05, 1.425, 1.325, 1.225, 1.125, 1.025, 0.925, 0.825, 0.725, 0.625, 0.525, 0.425, 0.325, 0.225, 0.125, 0.175, 0.275, 0.375, 0.475, 0.575, 0.675, 0.775, 0.875, 0.975, 1.075, 1.175, 1.275, 1.375];
const COLOR = ['255,255,255', '255,255,0', '205,92,92', '255,0,0', '255,105,180',
    '148,0,211', '191,239,255', '144,238,144', '255,165,0', '255,69,0',
    '124,242,0', '34,139,34', '127,255,212', '192,255,62', '139,69,19'];
const textAttr = {
    size: 20, //字号大小，一般为大写字母的高度
    height: 10, //文字的厚度
    weight: 'normal', //值为'normal'或'bold'，表示是否加粗
    style: 'normal', //值为'normal'或'italics'，表示是否斜体
    bevelThickness: 1, //倒角厚度
    bevelSize: 1, //倒角宽度
    curveSegments: 30,//弧线分段数，使得文字的曲线更加光滑
    bevelEnabled: true, //布尔值，是否使用倒角，意为在边缘处斜切
};

function rad(d) {
    return d * Math.PI / 180.0;
}

function getDistance(lat1, lng1, lat2, lng2) {
    var radLat1 = rad(lat1);
    var radLat2 = rad(lat2);
    var a = radLat1 - radLat2;
    var b = rad(lng1) - rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137; // EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000; //输出为公里
    return s;
}

function World(props) {
    const globeEl = useRef();
    window.globe = globeEl.current;
    const [filteredAirports, setAirports] = useState([]);
    const [filteredRoutes, setRoutes] = useState([]);
    const [fontface, setFontface] = useState();
    const [customData, setCustomData] = useState([]);
    const [intlMode, setIntlMode] = useState(undefined);


    const onLabelClick = (label, event, attr) => {
    }

    useEffect(() => {
        const refreshText = (allText, lngOffset) => {
            if (props.data.chinaMode) return [];
            const texts = [{
                label: allText.distance,
                lat: 11.1,
                lng: 113.75 + lngOffset,
                alt: 0.1,
                fontSize: 0.9,
                color: 'lightgrey'
            }, {
                label: allText.flightCount,
                lat: 7.7,
                lng: 114 + lngOffset,
                alt: 0.1,
                fontSize: 1.2,
                color: 'lightgrey'
            }, {
                label: allText.routeCount,
                lat: 4.35,
                lng: 114 + lngOffset,
                alt: 0.088,
                fontSize: 1.2,
                color: 'lightgrey'
            }, {
                label: allText.airportCount,
                lat: 1,
                lng: 114 + lngOffset,
                alt: 0.088,
                fontSize: 1.2,
                color: 'lightgrey'
            }, {
                label: allText.eastest,
                lat: 11.1,
                lng: 125.5 + lngOffset,
                alt: 0.078,
                fontSize: allText.eastest.length >= 6 ? 0.6 : allText.eastest.length >= 5 ? 0.75 : 0.9,
                color: 'lightgrey'
            }, {
                label: allText.southest,
                lat: 7.7,
                lng: 125.5 + lngOffset,
                alt: 0.068,
                fontSize: allText.southest.length >= 6 ? 0.6 : allText.southest.length >= 5 ? 0.75 : 0.9,
                color: 'lightgrey'
            }, {
                label: allText.westest,
                lat: 4.35,
                lng: 125.5 + lngOffset,
                alt: 0.063,
                fontSize: allText.westest.length >= 6 ? 0.6 : allText.westest.length >= 5 ? 0.75 : 0.9,
                color: 'lightgrey'
            }, {
                label: allText.northest,
                lat: 1,
                lng: 125.5 + lngOffset,
                alt: 0.058,
                fontSize: allText.northest.length >= 6 ? 0.6 : allText.northest.length >= 5 ? 0.75 : 0.9,
                color: 'lightgrey'
            }, {
                label: allText.longest,
                lat: 11.3,
                lng: 138.3 + lngOffset,
                alt: 0.058,
                fontSize: 0.5,
                color: 'lightgrey'
            }, {
                label: allText.shortest,
                lat: 7.7,
                lng: 138.3 + lngOffset,
                alt: 0.048,
                fontSize: 0.5,
                color: 'lightgrey'
            }, {
                label: allText.mostFrequent,
                lat: 4.2,
                lng: 138.3 + lngOffset,
                alt: 0.043,
                fontSize: allText.mostFrequentLen >= 5 ? 0.45 : 0.6,
                color: 'lightgrey'
            }, {
                label: allText.topCity,
                lat: 0.9,
                lng: 138.3 + lngOffset,
                alt: 0.038,
                fontSize: allText.topCity.length >= 6 ? 0.6 : allText.topCity.length >= 5 ? 0.75 : 0.9,
                color: 'lightgrey'
            },];
            return texts;
        }

        const {airports = [], flights = [], config = {}, fontface, chinaMode} = props.data;
        airports.forEach(e => {
            e.lat = +e.lat;
            e.lng = +e.lng;
        }); // 经纬度转下整数
        const byIata = indexBy(airports, 'id', false);
        config.bases && typeof config.bases === 'string' && (config.bases = config.bases.split(',').map(e => +e).filter(e => e));
        let flightData = flights.map(key => {
            if (key.stop) {
                const arr = [key.dep, key.stop, key.arr];
                if (key.dis) {
                    arr.push(key.dis);
                } else {
                    arr.push((byIata[key.dep] && byIata[key.stop] && byIata[key.arr] &&
                        (getDistance(byIata[key.dep].lat, byIata[key.dep].lng, byIata[key.stop].lat, byIata[key.stop].lng) +
                            getDistance(byIata[key.stop].lat, byIata[key.stop].lng, byIata[key.arr].lat, byIata[key.arr].lng))) || 0);
                }
                return arr;
            } else {
                const arr = [key.dep, key.arr];
                if (key.dis) {
                    arr.push(key.dis);
                } else {
                    arr.push((byIata[key.dep] && byIata[key.arr] &&
                        getDistance(byIata[key.dep].lat, byIata[key.dep].lng, byIata[key.arr].lat, byIata[key.arr].lng)) || 0);
                }
                return arr;
            }
        });
        let filteredAirports = [...new Set(flightData.reduce((a, b) => [...a, ...b.slice(0, b.length - 1)], []))].map(e => {
            if (!byIata[e]) {
                console.log('找不到: ' + e);
                return;
            }
            byIata[e].label = byIata[e].name;
            byIata[e].id = e;
            return byIata[e];
        }).filter(e => e);
        let routes = [];
        let filteredRoutes = [];
        if (chinaMode) {
            filteredAirports = Object.values(byIata).filter(e => e.label || e.inuse);
            filteredAirports.forEach(e => {
                if (!e.label) e.label = '';
            })
        }
        if (!chinaMode) {
            routes = flightData.reduce((arr, item) => {
                for (let i = 1; i < item.length - 1; i++) {
                    arr.push([item[i - 1], item[i]]);
                }
                return arr;
            }, []);
            routes = Object.entries(routes.reduce((a, b) => {
                a[b[0] + '-' + b[1]] = a[b[0] + '-' + b[1]] ? (a[b[0] + '-' + b[1]] + 1) : 1;
                return a;
            }, {})).map(([key, value]) => [...key.split('-'), value || 0]);
            routes = routes.reduce((a, b) => {
                for (let i = 0; i < b[2]; i++) {
                    a.push([b[0], b[1], i]);
                }
                return a;
            }, []);
            filteredRoutes = routes
                .filter(d => d[0] && d[1]) // non-stop flights only
                .map(d => ({
                    srcAirport: byIata[d[0]],
                    dstAirport: byIata[d[1]],
                    time: d[2],
                })).filter(e => e.srcAirport && e.dstAirport).sort((a, b) => a.dis - b.dis);
        }
        const intlCount = filteredAirports.filter(e => e.country !== '中国').length;
        const newIntlMode = chinaMode ? false : (intlCount >= 5); // 如果国际航点数大于5，则算国际模式
        const hasChangeIntlMode = intlMode !== newIntlMode || intlMode === undefined;

        filteredAirports.forEach(e => {
            if (chinaMode) {
                if (e.label) {
                    e.color = `rgba(${COLOR[Math.floor(Math.random() * 14) + 1]},1)`;
                } else {
                    e.color = `rgba(255, 255, 255, 0.8)`;
                }
            } else {
                e.color = `rgba(${COLOR[Math.floor(Math.random() * 15)]},0.5)`;
            }
        })

        filteredRoutes.forEach(e => {
            e.r1 = COLOR[Math.floor(Math.random() * 15)];
            e.r2 = COLOR[Math.floor(Math.random() * 15)];
        })
        let lngOffset = newIntlMode ? 18 : 0;
        const MAP_CENTER = {lat: 20, lng: 107 + lngOffset, altitude: newIntlMode ? 1.5 : 1.03};
        if (!props.loading) {
            globeEl.current.pointOfView(MAP_CENTER, hasChangeIntlMode ? 4000 : 1000);
        }
        let texts;

        if (!chinaMode) {
            let pointsExpectBases = filteredAirports.filter(e => (config.bases || []).indexOf(e.id) === -1);
            pointsExpectBases = pointsExpectBases.sort((a, b) => b.lng - a.lng);
            const eastest = (pointsExpectBases[0] && pointsExpectBases[0].name) || '';
            const westest = (pointsExpectBases[pointsExpectBases.length - 1] && pointsExpectBases[pointsExpectBases.length - 1].name) || '';
            pointsExpectBases = pointsExpectBases.sort((a, b) => b.lat - a.lat);
            const northest = (pointsExpectBases[0] && pointsExpectBases[0].name) || '';
            const southest = (pointsExpectBases[pointsExpectBases.length - 1] && pointsExpectBases[pointsExpectBases.length - 1].name) || '';
            flightData = flightData.sort((a, b) => a[a.length - 1] - b[b.length - 1]);
            const longest = flightData[flightData.length - 1] ? `${
                byIata[flightData[flightData.length - 1][0]]?.name}\n${
                byIata[flightData[flightData.length - 1][flightData[flightData.length - 1].length - 2]]?.name}\n${
                flightData[flightData.length - 1][flightData[flightData.length - 1].length - 1].toFixed(0)}km` : '';
            const shortest = filteredRoutes[0] ? `${
                byIata[flightData[0][0]]?.name}\n${
                byIata[flightData[0][flightData[0].length - 2]]?.name}\n${
                flightData[0][flightData[0].length - 1].toFixed(0)}km` : '';
            let tempObj = {};
            filteredRoutes.forEach(e => {
                if (e.srcAirport && e.dstAirport) {
                    const key = e.srcAirport.lat > e.dstAirport.lat ? `${e.srcAirport.name}\n${e.dstAirport.name}` : `${e.dstAirport.name}\n${e.srcAirport.name}`;
                    tempObj[key] = tempObj[key] ? tempObj[key] + 1 : 1;
                }
            });
            tempObj = Object.keys(tempObj).map(e => [e, tempObj[e]]);
            tempObj.sort((a, b) => b[1] - a[1]);
            const mostFrequent = (tempObj[0] && tempObj[0][0]) || '';
            const mostFrequentLen = mostFrequent && Math.max(...mostFrequent.split('\n').map(e => e.length));

            // 计算数据
            let distance = flightData.reduce((a, b) => a + (+b[b.length - 1] || 0), 0);
            if (distance < 100000) {
                distance = distance ? distance.toFixed(0) : ''
            } else if (distance < 1000000) {
                distance = (distance / 10000).toFixed(2) + 'w'
            } else if (distance < 10000000) {
                distance = (distance / 10000).toFixed(1) + 'w'
            } else {
                distance = (distance / 10000).toFixed(0) + 'w'
            }
            const flightCount = "" + (flights.length || '');
            const airportCount = "" + (filteredAirports.length || '');
            const routeCount = "" + (tempObj.length || '');

            let eachPointCount = filteredRoutes.reduce((obj, item) => {
                obj[item.dstAirport.id] = (obj[item.dstAirport.id] || 0) + 1;
                obj[item.srcAirport.id] = (obj[item.srcAirport.id] || 0) + 1;
                return obj;
            }, {});
            (config.bases || []).forEach(e => {
                eachPointCount[e] = 0
            });
            eachPointCount = Object.entries(eachPointCount).map(([key, value]) => [key, value]);
            eachPointCount = eachPointCount.sort((a, b) => b[1] - a[1]);
            const topCity = (eachPointCount[0] && eachPointCount[0][0] && byIata[eachPointCount[0][0]]?.name) || '';

            // 加载模型
            texts = refreshText({
                distance, flightCount, airportCount, routeCount, topCity,
                northest, southest, westest, eastest, mostFrequent, mostFrequentLen, longest, shortest
            }, lngOffset);
        }
        setAirports(chinaMode ? filteredAirports : filteredAirports.concat(texts));
        setRoutes(chinaMode ? [] : filteredRoutes);
        setFontface(fontface);
        setIntlMode(newIntlMode);

    }, [props.data.flights]);

    useEffect(() => {
        if (props.currentAirport) {
            let MAP_CENTER = {
                lat: (props.currentAirport?.lat || 0) - 5,
                lng: (props.currentAirport?.lng || 0),
                altitude: 0.5
            };
            globeEl.current.pointOfView(MAP_CENTER, 2000);
            MAP_CENTER = {
                lat: (props.currentAirport?.lat || 0) - 5,
                lng: (props.currentAirport?.lng || 0),
                altitude: 1.03
            };
            setTimeout(() => {
                globeEl.current.pointOfView(MAP_CENTER, 1000);
            }, 2000);
        }
    }, [props.currentAirport]);

    useEffect(() => {
        const {planeObj} = props.extraData;
        if (customData[4]) {
            let angleChange = -0.0005;
            let angleDiff = -0.00001;
            let firstTime = Date.now();
            let lastTime = firstTime;
            let lastSlowTime = lastTime;

            function animate() {
                const nowTime = Date.now();
                if (lastSlowTime && (nowTime - lastTime > 250)) {
                    lastSlowTime = nowTime;
                    if (nowTime - firstTime > 5000) {
                        lastSlowTime = 0;
                        props.handleLoaded();
                    }
                } else if (lastSlowTime && (nowTime - lastSlowTime > 600)) {
                    lastSlowTime = 0;
                    props.handleLoaded();
                }
                lastTime = nowTime;
                angleChange += angleDiff;
                if (angleChange > 0.001 || angleChange < -0.001) {
                    angleDiff *= -1;
                }
                customData[4].obj.rotateX(angleChange);
                customData[4].obj.rotateZ(angleChange);
                // console.log('anim', angleChange)
                requestAnimationFrame(() => animate());
            }
            animate();
        }
    }, [!!customData[4]]);

    useEffect(() => {
        const onWindowResize = () => {
            globeEl.current.renderer().setSize(window.innerWidth, window.innerHeight);
            const camera = globeEl.current.camera();
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        }
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.globe = null;
            window.removeEventListener('resize', onWindowResize)
        }
    }, []);

    useEffect(() => {
        if (intlMode === undefined) return;
        let lngOffset = intlMode ? 18 : 0;
        const {chinaMode} = props.data;
        const {planeObj} = props.extraData;
        let menu1, menu2, menu3;
        if (!chinaMode) {
            const menu1Texture = new THREE.TextureLoader().load(menu1Png);
            const menu1Material = new THREE.MeshBasicMaterial({map: menu1Texture});
            const menu2Texture = new THREE.TextureLoader().load(menu2Png);
            const menu2Material = new THREE.MeshBasicMaterial({map: menu2Texture});
            const menu3Texture = new THREE.TextureLoader().load(menu3Png);
            const menu3Material = new THREE.MeshBasicMaterial({map: menu3Texture});
            menu1Material.transparent = true;
            menu2Material.transparent = true;
            menu3Material.transparent = true;
            menu1 = new THREE.Mesh(new THREE.PlaneBufferGeometry(645 / 30, 796 / 30), menu1Material);
            menu2 = new THREE.Mesh(new THREE.PlaneBufferGeometry(645 / 30, 796 / 30), menu2Material);
            menu3 = new THREE.Mesh(new THREE.PlaneBufferGeometry(645 / 30, 796 / 30), menu3Material);
            menu1.rotateY(Math.PI / 180 * (120 + lngOffset));
            menu2.rotateY(Math.PI / 180 * (130 + lngOffset));
            menu3.rotateY(Math.PI / 180 * (140 + lngOffset));
        }
        if (planeObj && !customData[4]) {
            planeObj.scale.set(0.03, 0.03, 0.03);
            planeObj.rotateY(Math.PI / 180 * 130);
            customData[4] = {
                obj: planeObj,
                lat: 17.2,
                lng: 64 + lngOffset,
                alt: -0.05,
            };
        }
        if (!customData.length) {
            const logoTexture = new THREE.TextureLoader().load(baseUrl + '/_nuxt/img/logo1.84cd561.png');
            const logoMaterial = new THREE.MeshBasicMaterial({map: logoTexture});
            logoMaterial.transparent = true;
            const logo = new THREE.Mesh(new THREE.PlaneBufferGeometry(200 / 10, 98 / 10), logoMaterial);
            logo.rotateY(Math.PI / 180 * (90 + lngOffset));
            customData[3] = {
                obj: logo,
                lat: 14.2,
                lng: 113 + lngOffset,
                alt: 0.15,
            };
        } else {
            let oldOffset = customData[3].lng - 113
            customData[3].lng = 113 + lngOffset;
            if (customData[4]) {
                customData[4].obj.rotateY(Math.PI / 180 * (lngOffset - oldOffset));
                customData[4].lng = 64 + lngOffset;
            }
        }
        customData[0] = {
            obj: menu1,
            lat: 5.95,
            lng: 111 + lngOffset,
            alt: 0.1,
        };
        customData[1] = {
            obj: menu2,
            lat: 6,
            lng: 123 + lngOffset,
            alt: 0.07,
        };
        customData[2] = {
            obj: menu3,
            lat: 6,
            lng: 135.3 + lngOffset,
            alt: 0.05,
        };
        setCustomData([...customData]);
    }, [intlMode, props.data.chinaMode, props.extraData.planeObj]);

    useEffect(() => {
        if (!props.loading) {
            let lngOffset = intlMode ? 18 : 0;
            const MAP_CENTER = {lat: 20, lng: 107 + lngOffset, altitude: intlMode ? 1.5 : 1.03};
            globeEl.current.pointOfView(MAP_CENTER, 4000);
        }
    }, [props.loading]);

    if (!props.data) return;

    return (
        <Globe
            ref={globeEl}
            enablePointerInteraction={false}
            animateIn={false}
            globeImageUrl={props.data.globeImage}
            bumpImageUrl={props.data.bumpImage}
            backgroundImageUrl={props.data.bgImage}
            onGlobeReady={() => props.handleLoaded()}
            arcsData={filteredRoutes}
            arcStartLat={d => +d.srcAirport.lat}
            arcStartLng={d => +d.srcAirport.lng}
            arcEndLat={d => +d.dstAirport.lat}
            arcEndLng={d => +d.dstAirport.lng}
            arcAltitudeAutoScale={d => {
                return (d.dstAirport.lat - d.srcAirport.lat < 0) ? CURVE_N[+d.time] : CURVE_S[+d.time];
            }}
            arcDashLength={props.data.captureMode ? 1000000 : 0.8}
            arcStroke={intlMode ? 0.075 : 0.1}
            arcDashGap={0.1}
            arcDashAnimateTime={(props.loading ? 0 : intlMode ? 5000 : 1000)}
            arcsTransitionDuration={filteredRoutes.length > 100 || props.loading ? 0 : 1000}
            arcColor={d => {
                const hovered = props.currentAirport && (props.currentAirport.code === d.dstAirport.code || props.currentAirport.code === d.srcAirport.code);
                const op = !props.currentAirport ? OPACITY : hovered ? 0.9 : OPACITY / 4;
                return [`rgba(${d.r1}, ${op})`, `rgba(${d.r2}, ${op})`];
            }}
            arcCurveResolution={32}
            labelsData={filteredAirports}
            labelText="label"
            labelSize={d => d.fontSize || 0.35}
            labelDotRadius={0.08}
            labelAltitude={d => d.alt || 0}
            labelDotOrientation={d => d.position || 'bottom'}
            labelTypeFace={fontface}
            labelIncludeDot={d => !d.fontSize}
            labelColor={d => d.color}
            labelsTransitionDuration={props.loading ? 0 : 1000}
            customLayerData={customData}
            onGlobeClick={onLabelClick}
            customThreeObject={d => d.obj}
            customThreeObjectUpdate={(obj, d) => {
                Object.assign(obj.position, globeEl.current.getCoords(d.lat, d.lng, d.alt));
            }}
        />
    );
}

export default World;
