import React, {Component, useState, useRef, useEffect} from 'react';
import './index.css';
import indexBy from "index-array-by";
import SwiperBox from "./SwiperBox";
import LongestImg from "../../assets/longest.png";
import ShortestImg from "../../assets/shortest.png";
import TopRouteImg from "../../assets/toproute.png";
import TopAirportImg from "../../assets/topairport.png";
import TopCountryImg from "../../assets/topcountry.png";
import TopProvinceImg from "../../assets/topprovince.png";
import TopAirlineImg from "../../assets/topairline.png";
import LatlngImg from "../../assets/latlng.png";
import MemoryImg from "../../assets/memory.png";
import AboutClassImg from "../../assets/aboutclass.png";
import AboutTypeImg from "../../assets/abouttype.png";
import AboutACImg from "../../assets/aboutac.png";
import AboutCoatingImg from "../../assets/aboutcoating.png";

const Skytrax5StarAirlines = ['QR', 'SQ', 'NH', 'JL', 'EK', 'QF', 'AF', 'TK', 'KE', 'LX'];

function rad(d) {
    return d * Math.PI / 180.0;
}

function getDistance(lat1, lng1, lat2, lng2) {
    var radLat1 = rad(lat1);
    var radLat2 = rad(lat2);
    var a = radLat1 - radLat2;
    var b = rad(lng1) - rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137; // EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000; //输出为公里
    return s;
}

function Detail(props) {
    const [layerClosing, setLayerClosing] = useState(false);
    const [info, setInfo] = useState(null);
    const [positionMost, setPositionMost] = useState("eastmost");
    const [topAirportsMode, setTopAirportsMode] = useState("all");
    const [topAirlinesMode, setTopAirlinesMode] = useState("all");
    const handleClose = () => {
        setLayerClosing(true);
    }
    const handleAirportClick = (airport) => {
        if (!airport) return;
        handleClose();
        props.handleEnterAirport && props.handleEnterAirport(airport);
    }
    const onLayerAnimEnd = () => {
        if (layerClosing) {
            setLayerClosing(false);
            props.handleClose();
        }
    }
    useEffect(() => {
        const info = {};
        const {airports = [], airlines = [], flights = [], config = {}, aircrafts = [], fontface} = props.data;
        airports.forEach(e => {
            e.lat = +e.lat;
            e.lng = +e.lng;
        }); // 经纬度转下整数
        const byIata = indexBy(airports, 'id', false);
        const byAirlineCode = indexBy(airlines, 'id', false);
        const byReg = indexBy(aircrafts, 'id', false);
        Object.keys(byIata).forEach(e => byIata[e].id = e);
        Object.keys(byReg).forEach(e => byReg[e].id = e);
        Object.keys(byAirlineCode).forEach(e => byAirlineCode[e].id = e);
        config.bases && typeof config.bases === 'string' && (config.bases = config.bases.split(','));
        config.bases && (config.bases.forEach(base => {
            byIata[base] && (byIata[base].base = 1)
        }))
        let flightData = flights.map(flight => ({
            ...flight,
            dep: byIata[flight.dep],
            arr: byIata[flight.arr],
            stop: flight.stop ? byIata[flight.stop] : undefined,
            airline: flight.al,
            ac: flight.reg && byReg[flight.reg],
        })).filter(flight => flight.dep && flight.arr);
        flightData.forEach(flight => {
            flight.dep && (byIata[flight.dep?.id].count = (byIata[flight.dep?.id]?.count || 0) + 1);
            flight.arr && (byIata[flight.arr?.id].count = (byIata[flight.arr?.id]?.count || 0) + 1);
            flight.stop && (byIata[flight.stop?.id].count = (byIata[flight.stop?.id]?.count || 0) + 1);
            flight.dis = flight.dis ? flight.dis : flight.stop ? (
                getDistance(flight.dep?.lat, flight.dep?.lng, flight.stop?.lat, flight.stop?.lng) +
                getDistance(flight.stop?.lat, flight.stop?.lng, flight.arr?.lat, flight.arr?.lng)
            ) : getDistance(flight.dep?.lat, flight.dep?.lng, flight.arr?.lat, flight.arr?.lng)
        });
        Object.keys(byIata).forEach(e => {
            (!byIata[e].count) && (delete byIata[e])
        })
        let routeCountObj = {};
        let realRouteCountObj = {};
        flightData.forEach(flight => {
            let key;
            if (flight.dep?.lat > flight.arr?.lat) {
                key = `${flight.dep?.id || ''}${flight.stop?.id ? '-' : ''}${flight.stop?.id || ''}-${flight.arr?.id || ''}`;
            } else {
                key = `${flight.arr?.id || ''}${flight.stop?.id ? '-' : ''}${flight.stop?.id || ''}-${flight.dep?.id || ''}`;
            }
            routeCountObj[key] = (routeCountObj[key] || 0) + 1;
            if (!flight.stop) {
                realRouteCountObj[key] = (realRouteCountObj[key] || 0) + 1;
            } else {
                if (flight.dep?.lat > flight.arr?.lat) {
                    key = `${flight.dep?.id || ''}-${flight.arr?.id || ''}`;
                } else {
                    key = `${flight.arr?.id || ''}-${flight.dep?.id || ''}`;
                }
                realRouteCountObj[key] = (realRouteCountObj[key] || 0) + 1;
                if (flight.dep?.lat > flight.stop?.lat) {
                    key = `${flight.dep?.id || ''}-${flight.stop?.id || ''}`;
                } else {
                    key = `${flight.stop?.id || ''}-${flight.dep?.id || ''}`;
                }
                realRouteCountObj[key] = (realRouteCountObj[key] || 0) + 1;
                if (flight.arr?.lat > flight.stop?.lat) {
                    key = `${flight.arr?.id || ''}-${flight.stop?.id || ''}`;
                } else {
                    key = `${flight.stop?.id || ''}-${flight.arr?.id || ''}`;
                }
                realRouteCountObj[key] = (realRouteCountObj[key] || 0) + 1;
            }
        });
        Object.keys(routeCountObj).forEach(e => routeCountObj[e] = {count: routeCountObj[e]});
        Object.keys(realRouteCountObj).forEach(e => realRouteCountObj[e] = {count: realRouteCountObj[e]});
        flightData.forEach(flight => {
            let key;
            if (flight.dep?.lat > flight.arr?.lat) {
                key = `${flight.dep?.id || ''}${flight.stop?.id ? '-' : ''}${flight.stop?.id || ''}-${flight.arr?.id || ''}`;
            } else {
                key = `${flight.arr?.id || ''}${flight.stop?.id ? '-' : ''}${flight.stop?.id || ''}-${flight.dep?.id || ''}`;
            }
            routeCountObj[key] && (routeCountObj[key].dis = flight.dis);
            flight.count = routeCountObj[key]?.count || 0;
        })
        let routeCountArr = Object.keys(routeCountObj).map(key => {
            const airports = key.split('-');
            return {
                dep: byIata[airports[0]],
                arr: airports.length > 1 && byIata[airports[airports.length - 1]],
                stop: (airports.length === 3 && byIata[airports[1]]) || undefined,
                count: routeCountObj[key].count,
                dis: routeCountObj[key].dis
            }
        })
        let realRouteCountArr = Object.keys(realRouteCountObj).map(key => {
            const airports = key.split('-');
            return {
                dep: byIata[airports[0]],
                arr: airports.length > 1 && byIata[airports[airports.length - 1]],
                count: realRouteCountObj[key].count,
            }
        })


        info.airportCount = Object.values(byIata).length;
        // 最短的十条航线
        routeCountArr = routeCountArr.sort((a, b) => a.dis - b.dis);
        info.shortestFlights = routeCountArr.filter((_, i) => i < 10);
        // 最长的十条航线
        routeCountArr = routeCountArr.sort((a, b) => b.dis - a.dis);
        info.longestFlights = routeCountArr.filter((_, i) => i < 10);
        // 最频繁的十条航线
        routeCountArr = routeCountArr.sort((a, b) => b.count - a.count);
        realRouteCountArr = realRouteCountArr.sort((a, b) => b.count - a.count);
        info.topRoutes = routeCountArr.filter((e, i) => i < 10 && e.count > 0);
        // 最频繁的十个机场
        const topAirports = Object.values(byIata).sort((a, b) => (b.count || 0) - (a.count || 0))
            .filter(e => e.count > 0);
        info.topAirports = {
            all: topAirports.filter(e => !e.base).slice(0, 10),
            domestic: topAirports.filter(e => e.country === '中国' && !e.base).slice(0, 10),
            intl: topAirports.filter(e => e.country !== '中国' && !e.base).slice(0, 10),
        };
        ['all', 'domestic', 'intl'].forEach(mode => {
            info.topAirports[mode] = info.topAirports[mode].map(airport => ({
                ...airport,
                topDest: realRouteCountArr.filter(e => e.dep?.id == airport.id || e.arr?.id == airport.id)
                    .map(e => ({dist: e.dep?.id == airport.id ? e.arr : e.dep, count: e.count || 0}))
                    .filter((e, i) => i < 3)
            }));
        });
        // 最频繁的十个省份
        info.topProvinces = Object.entries(Object.values(byIata).reduce((obj, item) => {
            item.country === '中国' && item.province && (obj[item.province] = (obj[item.province] || 0) + (item.count || 0))
            return obj;
        }, {}))
            .sort((a, b) => b[1] - a[1]).filter(e => e[1]);
        info.provinceCount = info.topProvinces.length;
        info.topProvinces = info.topProvinces.filter((e, i) => i < 10 && e[1] > 0)
            .map(([province, count]) => ({
                name: province, count,
                topAirports: topAirports.filter(airport => airport.province === province && airport.count).filter((_, i) => i < 3)
            }));
        // 最频繁的十个国家
        info.topCountries = Object.entries(Object.values(byIata).reduce((obj, item) => {
            item.country && (obj[item.country] = (obj[item.country] || 0) + (item.count || 0))
            return obj;
        }, {}))
            .sort((a, b) => b[1] - a[1]).filter(e => e[1]);
        info.countryCount = info.topCountries.length;
        info.topCountries = info.topCountries
            .filter((e, i) => i < 10 && e[1] > 0)
            .map(([country, count]) => ({
                name: country, count,
                topAirports: topAirports.filter(airport => airport.country === country && airport.count).filter((_, i) => i < 3)
            }));
        if (info.countryCount === 0 || (info.countryCount === 1 && info.topCountries[0].country === '中国')) {
            info.topCountries = null;
        }
        // 最频繁的十个航空公司
        flightData = flightData.sort((a, b) => (+b.dis) - (+a.dis));
        const topAirlines = Object.entries(flightData.reduce((obj, item) => {
            item.airline && (obj[item.airline] = (obj[item.airline] || 0) + 1);
            return obj;
        }, {}))
            .sort((a, b) => b[1] - a[1])
            .filter((e) => e[1] > 0)
            .map(([airline, count]) => ({
                ...(byAirlineCode[airline] || {}), id: airline,
                count, longest: flightData.find(e => e.airline == airline)
            }));
        info.topAirlines = {
            all: topAirlines.slice(0, 10),
            domestic: topAirlines.filter(e => e.country === '中国').slice(0, 10),
            intl: topAirlines.filter(e => e.country !== '中国').slice(0, 10),
        };
        ['all', 'domestic', 'intl'].forEach(mode => {
            info.topAirports[mode] = info.topAirports[mode].map(airport => ({
                ...airport,
                topDest: realRouteCountArr.filter(e => e.dep?.id == airport.id || e.arr?.id == airport.id)
                    .map(e => ({dist: e.dep?.id == airport.id ? e.arr : e.dep, count: e.count || 0}))
                    .filter((e, i) => i < 3)
            }));
        });
        // 最东南西北航点
        info.westmost = Object.values(byIata).sort((a, b) => a.lng - b.lng).filter((_, i) => i < 5);
        info.eastmost = Object.values(byIata).sort((a, b) => b.lng - a.lng).filter((_, i) => i < 5);
        info.southmost = Object.values(byIata).sort((a, b) => a.lat - b.lat).filter((_, i) => i < 5);
        info.northmost = Object.values(byIata).sort((a, b) => b.lat - a.lat).filter((_, i) => i < 5);
        // 消失的机场
        flightData = flightData.sort((a, b) => (+b.date) - (+a.date));
        info.closedAirports = Object.values(byIata).filter(e => !e.inuse).map(airport => ({
            ...airport,
            lastTime: flightData.find(e => e.dep?.id == airport.id || e.arr?.id == airport.id)?.date
        })).filter((_, i) => i < 15);
        // 我和舱位的那些事
        const flightDataWithClass = flightData.filter(e => e.c)
        if (flightDataWithClass.length) {
            info.aboutClass = [];
            const firstClassFlights = flightData.filter(e => e.c === 'F');
            const businessClassFlights = flightData.filter(e => e.c === 'J');
            const epClassFlights = flightData.filter(e => e.c === 'W');
            const economyClassFlights = flightData.filter(e => e.c === 'Y');
            let classCountWithRate = [
                ['头等舱', firstClassFlights.length, 'First Class'], ['公务舱', businessClassFlights.length, 'Business Class'], ['超经', epClassFlights.length, 'Economy Plus'], ['经济舱', economyClassFlights.length, 'Economy Class']
            ].sort((a, b) => b[1] - a[1]);
            info.aboutClass.push({
                title: '最常选择的飞机舱位',
                main: classCountWithRate[0][0],
                main_mark: classCountWithRate[0][2],
                sub: `总共搭乘${classCountWithRate[0][0]}${classCountWithRate[0][1]}次`
            })
            const fjRate = ((firstClassFlights.length + businessClassFlights.length) / flightDataWithClass.length * 100).toFixed(1) + '%';
            info.aboutClass.push({
                title: '头等舱/公务舱出行的比例',
                num: fjRate,
                sub: fjRate !== '0%' ? `至今已乘坐${firstClassFlights.length + businessClassFlights.length}次两舱了` : '什么时候首次体验下呢?'
            });
            if (firstClassFlights.length || businessClassFlights.length) {
                let fpAirlineCount = {};
                let firstAirlineCount = {};
                businessClassFlights.forEach(e => {
                    fpAirlineCount[e.airline] = (fpAirlineCount[e.airline] || 0) + 1
                })
                firstClassFlights.forEach(e => {
                    fpAirlineCount[e.airline] = (fpAirlineCount[e.airline] || 0) + 1
                    firstAirlineCount[e.airline] = (firstAirlineCount[e.airline] || 0) + 1
                })
                fpAirlineCount = Object.keys(fpAirlineCount).map(key => ({
                    id: key,
                    count: fpAirlineCount[key]
                })).filter(e => e.count)
                    .sort((a, b) => b.count - a.count)
                firstAirlineCount = Object.keys(firstAirlineCount).map(key => ({
                    id: key,
                    count: firstAirlineCount[key]
                }))
                    .sort((a, b) => b.count - a.count)
                info.aboutClass.push({
                    title: '解锁两舱姿势的航司个数', num: fpAirlineCount.length,
                    sub: `另外${topAirlines.length - fpAirlineCount.length}家只在后舱坐过`
                })
                const fpFirstAirlineCount = topAirlines.find(e => e.id == fpAirlineCount[0].id)?.count || fpAirlineCount[0].count;
                const topsRate = Math.round(fpAirlineCount[0].count / fpFirstAirlineCount * 100) + '%';
                if (fpAirlineCount.length) {
                    info.aboutClass.push({
                        title: '最偏爱两舱的航司',
                        main: byAirlineCode[fpAirlineCount[0].id]?.name || '未知',
                        main_mark: byAirlineCode[fpAirlineCount[0].id]?.en || 'Unknown',
                        sub: `其中${topsRate}的行程选择了两舱`, code: byAirlineCode[fpAirlineCount[0].id]?.code || '',
                    })

                    if (fpAirlineCount.length > 1) {
                        info.aboutClass.push({
                            title: '排在' + (byAirlineCode[fpAirlineCount[0].id]?.name || '未知') + '之后的是',
                            main: byAirlineCode[fpAirlineCount[1].id]?.name || '未知', code: byAirlineCode[fpAirlineCount[1].id]?.code,
                            main_mark: byAirlineCode[fpAirlineCount[1].id]?.en || 'Unknown',
                            sub: fpAirlineCount.length === 2 ? '期待解锁更多航司的两舱' : `排在第三的是${byAirlineCode[fpAirlineCount[2].id]?.name || '未知'}`
                        })
                    }
                    const topIsDomestic = byAirlineCode[fpAirlineCount[0].id]?.country === '中国';
                    if (topIsDomestic) {
                        const tempRank = fpAirlineCount.filter(e => byAirlineCode[e.id]?.country !== '中国');
                        if (tempRank.length) {
                            const fpFirstAirlineCount = topAirlines.find(e => e.id == tempRank[0].id)?.count || tempRank[0].count;
                            const topsRate = Math.round(tempRank[0].count / fpFirstAirlineCount * 100) + '%';
                            info.aboutClass.push({
                                title: '最偏爱两舱的外籍航司',
                                main_mark: byAirlineCode[tempRank[0].id]?.en || 'Unknown',
                                code: byAirlineCode[tempRank[0].id]?.code || '',
                                main: byAirlineCode[tempRank[0].id]?.name || '未知',
                                sub: `其中${topsRate}的行程选择了两舱`
                            })
                        }
                    } else {
                        const tempRank = fpAirlineCount.filter(e => byAirlineCode[e.id]?.country === '中国');
                        if (tempRank.length) {
                            const fpFirstAirlineCount = topAirlines.find(e => e.id == tempRank[0].id)?.count || tempRank[0].count;
                            const topsRate = Math.round(tempRank[0].count / fpFirstAirlineCount * 100) + '%';
                            info.aboutClass.push({
                                title: '最偏爱两舱的国内航司',
                                main_mark: byAirlineCode[tempRank[0].id]?.en || 'Unknown',
                                code: byAirlineCode[tempRank[0].id]?.code || '',
                                main: byAirlineCode[tempRank[0].id]?.name || '未知',
                                sub: `其中${topsRate}的行程选择了两舱`
                            })
                        }
                    }
                }
                // const skytraxCount = fpAirlineCount.filter(e => Skytrax5StarAirlines.indexOf(e.id) !== -1);
                // info.aboutClass.push({
                //     title: 'Skytrax TOP10两舱解锁', num: `${skytraxCount.length}/10`,
                //     sub: skytraxCount.length ? `${byAirlineCode[skytraxCount[0].id]?.name || '未知'}是你的两舱首选` : '找机会选家五星航司吧'
                // })
                info.aboutClass.push({
                    title: '搭乘头等舱的次数', num: firstClassFlights.length,
                    sub: firstClassFlights.length ? `解锁了${firstAirlineCount.length}家航司的顶级体验` : '有机会乘坐一定不虚此行'
                });
                if (firstAirlineCount.length) {
                    const fFirstAirlineCount = topAirlines.find(e => e.id == firstAirlineCount[0].id)?.count || firstAirlineCount[0].count;
                    const topsRate = Math.round(firstAirlineCount[0].count / fFirstAirlineCount * 100) + '%';
                    info.aboutClass.push({
                        title: '最偏爱头等舱的航司',
                        main: byAirlineCode[firstAirlineCount[0].id]?.name || '未知', code: byAirlineCode[firstAirlineCount[0].id]?.code || '',
                        main_mark: byAirlineCode[firstAirlineCount[0].id]?.en || 'Unknown',
                        sub: `其中${topsRate}行程选择了头等舱`
                    });
                    const hasCZ = firstAirlineCount.find(e => byAirlineCode[e.id]?.code === 'CZ');
                    const hasMU = firstAirlineCount.find(e => byAirlineCode[e.id]?.code === 'MU');
                    const hasMF = firstAirlineCount.find(e => byAirlineCode[e.id]?.code === 'MF');
                    const hasCA = firstAirlineCount.find(e => byAirlineCode[e.id]?.code === 'CA');
                    const domesticCount = (hasCZ ? 1 : 0) + (hasCA ? 1 : 0) + (hasMU ? 1 : 0) + (hasMF ? 1 : 0);
                    info.aboutClass.push({
                        title: '国内航司头等舱解锁进度', num: `${domesticCount}/4`,
                        sub: domesticCount === 4 ? '大功告成!' : `还未解锁${[!hasCZ ? '白菜' : '', !hasMU ? '货航' : '', !hasCA ? '载旗' : '', !hasMF ? '白鹭' : ''].filter(e => e).join('/')}`
                    });
                }
            }
            if (epClassFlights.length) {
                let epAirlineCount = {};
                epClassFlights.forEach(e => {
                    epAirlineCount[e.airline] = (epAirlineCount[e.airline] || 0) + 1
                });
                epAirlineCount = Object.keys(epAirlineCount).map(key => ({id: key, count: epAirlineCount[key]}))
                    .sort((a, b) => b.count - a.count);
                info.aboutClass.push({
                    title: '搭乘超级经济舱的次数', num: epClassFlights.length,
                    sub: `最常乘坐${byAirlineCode[epAirlineCount[0].id]?.name || '未知'},${epAirlineCount[0].count}次`
                });
            }

        }
        // 我和机型的那些事
        const flightDataWithType = flightData.filter(e => e.type)
        if (flightDataWithType.length) {
            let acTypeSummary = [
                {
                    name: '波音707系列',
                    en: 'Boeing 707',
                    list: flightDataWithType.filter(e => e.type.indexOf('70') === 0 || e.type.indexOf('B70') === 0)
                },
                {
                    name: '波音717系列',
                    en: 'Boeing 717',
                    list: flightDataWithType.filter(e => e.type.indexOf('71') === 0 || e.type.indexOf('B71') === 0)
                },
                {
                    name: '波音727系列',
                    en: 'Boeing 727',
                    list: flightDataWithType.filter(e => e.type.indexOf('72') === 0 || e.type.indexOf('B72') === 0)
                },
                {
                    name: '波音737系列', en: 'Boeing 737',
                    list: flightDataWithType.filter(e => e.type.indexOf('73') === 0 || e.type.indexOf('7M') === 0 || e.type.indexOf('U') === 0 || e.type.indexOf('B73') === 0)
                },
                {
                    name: '波音747系列',
                    en: 'Boeing 747',
                    list: flightDataWithType.filter(e => e.type.indexOf('74') === 0 || e.type.indexOf('B74') === 0)
                },
                {
                    name: '波音757系列',
                    en: 'Boeing 757',
                    list: flightDataWithType.filter(e => e.type.indexOf('75') === 0 || e.type.indexOf('B75') === 0)
                },
                {
                    name: '波音767系列',
                    en: 'Boeing 767',
                    list: flightDataWithType.filter(e => e.type.indexOf('76') === 0 || e.type.indexOf('B76') === 0)
                },
                {
                    name: '波音777系列',
                    en: 'Boeing 777',
                    list: flightDataWithType.filter(e => e.type.indexOf('77') === 0 || e.type.indexOf('B77') === 0)
                },
                {
                    name: '波音787系列',
                    en: 'Boeing 787',
                    list: flightDataWithType.filter(e => e.type.indexOf('78') === 0 || e.type.indexOf('B78') === 0)
                },
                {name: '庞巴迪', en: 'Bombardier', list: flightDataWithType.filter(e => e.type.indexOf('CR') === 0)},
                {name: '巴航工ERJ系列', en: 'Embraer ERJ', list: flightDataWithType.filter(e => e.type.indexOf('E') === 0)},
                {
                    name: '空客220系列', en: 'Airbus 220',
                    list: flightDataWithType.filter(e => e.type.indexOf('22') === 0 || e.type.indexOf('A22') === 0 || e.type.indexOf('BCS') === 0)
                },
                {
                    name: '空客300系列', en: 'Airbus 300',
                    list: flightDataWithType.filter(e => e.type.indexOf('30') === 0 || e.type.indexOf('3S') === 0 || e.type.indexOf('AB') === 0 || e.type.indexOf('A3ST') === 0)
                },
                {
                    name: '空客310系列',
                    en: 'Airbus 310',
                    list: flightDataWithType.filter(e => e.type.indexOf('310') === 0 || e.type.indexOf('311') === 0 || e.type.indexOf('312') === 0
                        || e.type.indexOf('313') === 0 || e.type.indexOf('314') === 0 || e.type.indexOf('A310') === 0 || e.type.indexOf('A311') === 0
                        || e.type.indexOf('A312') === 0 || e.type.indexOf('A313') === 0 || e.type.indexOf('A314') === 0)
                },
                {
                    name: '空客318系列',
                    en: 'Airbus 318',
                    list: flightDataWithType.filter(e => e.type.indexOf('318') === 0 || e.type.indexOf('317') === 0 || e.type.indexOf('316') === 0
                        || e.type.indexOf('315') === 0 || e.type.indexOf('18') === 0 || e.type.indexOf('A318') === 0 || e.type.indexOf('A317') === 0 || e.type.indexOf('A316') === 0 || e.type.indexOf('A315') === 0)
                },
                {
                    name: '空客319系列', en: 'Airbus 319',
                    list: flightDataWithType.filter(e => (/^31[A-Z9]/.test(e.type) || /^19[A-Z]/.test(e.type) || e.type.indexOf('A319') === 0 || e.type.indexOf('A19N') === 0))
                },
                {
                    name: '空客320系列', en: 'Airbus 320',
                    list: flightDataWithType.filter(e => /^32[A-K2-9]/.test(e.type) || e.type.indexOf('20') === 0 || e.type.indexOf('A320') === 0 || e.type.indexOf('A20N') === 0)
                },
                {
                    name: '空客321系列', en: 'Airbus 321',
                    list: flightDataWithType.filter(e => /^32[1L-Z]/.test(e.type) || e.type.indexOf('21') === 0 || e.type.indexOf('A321') === 0 || e.type.indexOf('A21N') === 0)
                },
                {name: '空客330系列', en: 'Airbus 330', list: flightDataWithType.filter(e => e.type.indexOf('33') === 0 || e.type.indexOf('A33') === 0)},
                {name: '空客340系列', en: 'Airbus 340', list: flightDataWithType.filter(e => e.type.indexOf('34') === 0 || e.type.indexOf('A34') === 0)},
                {name: '空客350系列', en: 'Airbus 350', list: flightDataWithType.filter(e => e.type.indexOf('35') === 0 || e.type.indexOf('A35') === 0)},
                {name: '空客380系列', en: 'Airbus 380', list: flightDataWithType.filter(e => e.type.indexOf('38') === 0 || e.type.indexOf('A38') === 0)},
                {
                    name: '英国宇航系列',
                    en: 'British Aerospace Corporation',
                    list: flightDataWithType.filter(e => e.type.indexOf('14') === 0 || e.type.indexOf('RJ') === 0 || e.type.indexOf('J41') === 0
                        || e.type.indexOf('AC') === 0 || e.type.indexOf('VC') === 0 || e.type.indexOf('BA') === 0 || e.type.indexOf('B4') === 0)
                },
                {
                    name: '图波列夫',
                    en: 'Tupolev(Tu Series)',
                    list: flightDataWithType.filter(e => e.type.indexOf('TU') === 0 || e.type.indexOf('T1') === 0 || e.type.indexOf('T2') === 0)
                },
                {name: '伊尔系列', en: 'Ilyshin Series', list: flightDataWithType.filter(e => e.type.indexOf('IL') === 0)},
                {name: '运系列', en: 'Yun Series', list: flightDataWithType.filter(e => e.type.indexOf('YN') === 0)},
                {name: '翔凤支线飞机', en: 'ARJ21', list: flightDataWithType.filter(e => e.type.indexOf('AR') === 0 || e.type.indexOf('AJ') === 0)},
                {name: '新舟60', en: 'MA60', list: flightDataWithType.filter(e => e.type.indexOf('MA6') === 0)},
                {
                    name: '麦道系列', en: 'MD/DC Series',
                    list: flightDataWithType.filter(e => e.type.indexOf('DC') === 0 || e.type.indexOf('D8') === 0 || e.type.indexOf('D9') === 0 || e.type.indexOf('D1') === 0 ||
                        e.type.indexOf('M1') === 0 || e.type.indexOf('M8') === 0 || e.type.indexOf('M9') === 0)
                },
            ]
            acTypeSummary.forEach(e => e.count = e.list.length)
            acTypeSummary = acTypeSummary.sort((a, b) => b.count - a.count)
            info.aboutType = [];
            info.aboutType.push({title: '打卡过的机型数量', num: acTypeSummary.filter(e => e.count).length, sub: '注:不细分到具体子机型'})
            info.aboutType.push({
                title: '最常乘坐的机型', main: acTypeSummary[0].name, main_mark: acTypeSummary[0].en,
                sub: `总共乘坐了${acTypeSummary[0].count}次，占比${(acTypeSummary[0].count / flightDataWithType.length * 100).toFixed(1)}%`
            })
            if (acTypeSummary.length > 1 && acTypeSummary[1].count) info.aboutType.push({
                title: '第二常乘坐的机型',
                main: acTypeSummary[1].name, main_mark: acTypeSummary[1].en,
                sub: `总共乘坐了${acTypeSummary[1].count}次，占比${(acTypeSummary[1].count / flightDataWithType.length * 100).toFixed(1)}%`
            })
            if (acTypeSummary.length > 2 && acTypeSummary[2].count) info.aboutType.push({
                title: '排在' + acTypeSummary[1].name + '之后的是',
                main: acTypeSummary[2].name, main_mark: acTypeSummary[2].en,
                sub: `总共乘坐了${acTypeSummary[2].count}次，占比${(acTypeSummary[2].count / flightDataWithType.length * 100).toFixed(1)}%`
            })
            if (acTypeSummary.length > 3 && acTypeSummary[3].count) info.aboutType.push({
                title: '排在' + acTypeSummary[2].name + '之后的是',
                main: acTypeSummary[3].name, main_mark: acTypeSummary[3].en,
                sub: `总共乘坐${acTypeSummary[3].count}次，占比${(acTypeSummary[3].count / flightDataWithType.length * 100).toFixed(1)}%`
            })
            const bigType = flightDataWithType.filter(e => e.type.indexOf('76') === 0 || e.type.indexOf('77') === 0 || e.type.indexOf('78') === 0 || e.type.indexOf('74') === 0 || e.type.indexOf('33') === 0 || e.type.indexOf('34') === 0 ||
                e.type.indexOf('35') === 0 || e.type.indexOf('38') === 0 || e.type.indexOf('30') === 0 || e.type === '310' || e.type.indexOf('M1') === 0 || e.type.indexOf('D1') === 0);
            const bigRate = (bigType.length / flightDataWithType.length * 100).toFixed(1) + '%';
            info.aboutType.push({
                title: '乘坐宽体机出行的比例',
                num: bigRate,
                sub: `乘坐宽体机出行了${bigType.length}/${flightDataWithType.length}次`
            })
            const boeingCount = acTypeSummary.filter(e => e.name.indexOf('波音') === 0).reduce((a, b) => a + b.count, 0);
            const airbusCount = acTypeSummary.filter(e => e.name.indexOf('空中客车') === 0).reduce((a, b) => a + b.count, 0);
            info.aboutType.push({
                title: '更喜欢A家还是B家的飞机?',
                main: boeingCount === airbusCount ? '平分秋色' : boeingCount > airbusCount ? '波音' : '空中客车',
                main_mark: boeingCount === airbusCount ? 'Not Exact' : boeingCount > airbusCount ? 'Boeing' : 'Airbus',
                sub: boeingCount === airbusCount ? `两者均搭乘${boeingCount || 0}次` : `${boeingCount < airbusCount ? '空客' : '波音'}比${boeingCount > airbusCount ? '空客' : '波音'}多乘坐了${
                    (Math.abs(boeingCount - airbusCount) / flightDataWithType.length * 100).toFixed(1)}%`
            })
            const domesticCount = acTypeSummary.filter(e => e.name === '翔凤支线飞机' || e.name === '新舟60' || e.name === '运系列').reduce((a, b) => a + b.count, 0);
            const domesticRate = (domesticCount / flightDataWithType.length * 100).toFixed(1) + '%';
            info.aboutType.push({title: '乘坐国产机型出行的比例', num: domesticRate, sub: `已支持国产${domesticCount}次，继续加油`});
            let findAim = acTypeSummary.find(e => e.name === '空中客车380系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '永远的空中巨无霸:A380',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '波音747系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '不老的空中女皇:B747',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '空中客车350系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '空客新旗舰产品:墨镜侠A350',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '波音787系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '起飞的梦想客机:波音B787',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '翔凤支线飞机')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '国产工业崛起:商飞ARJ21',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '新舟60')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '国产支线小飞机:新舟60',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '巴航工业ERJ系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '支线飞机:巴航工业ERJ',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '庞巴迪')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '支线飞机:庞巴迪CRJ',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '波音757系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '告别国内机队4年的B757',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '波音767系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '告别国内机队8年的B767',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '空中客车340系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '告别国内机队8年的A340',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
            findAim = acTypeSummary.find(e => e.name === '麦道系列')
            if (findAim && findAim.count) {
                const als = [...new Set(findAim.list.map(e => e && e.airline).filter(e => e))];
                info.aboutType.push({
                    title: '再见，麦道！',
                    num: findAim.count + '次',
                    sub: `解锁${als.length}家航司:${als.map(e => byAirlineCode[e]?.code).filter(e => e).join('/')},最近:${findAim.list[0].date || '未知日期'}`
                })
            }
        }
        // 我和飞机的那些事
        const flightDataWithAC = flightData.filter(e => e.ac);
        if (flightDataWithAC.length) {
            info.aboutAircraft = [];
            let acObj = {}
            flightDataWithAC.forEach(e => {
                if (!acObj[e.ac.id]) acObj[e.ac.id] = {...e.ac, count: 0, flights: []};
                acObj[e.ac.id].type = e.type;
                acObj[e.ac.id].count++;
                acObj[e.ac.id].flights.push(e);
            })
            let acArr = Object.values(acObj).sort((a, b) => b.count - a.count);
            info.aboutAircraft.push({
                title: '搭乘的不同飞机数量', num: acArr.length,
                sub: acArr.length === flightDataWithType.length ? '看来每次坐的都是新飞机!' : `看来有${flightDataWithType.length - acArr.length}次都是遇到老熟机`
            });
            info.aboutAircraft.push({
                title: '坐过最多的飞机', main: acArr[0].code,
                main_mark: `${byAirlineCode[acArr[0].al]?.name || ''},${acArr[0].st},${(acArr[0].day / 365).toFixed(1)}年,${acArr[0].type || '-'}`,
                sub: `至今已坐过${acArr[0].count}次`, code: byAirlineCode[acArr[0].al]?.code
            });
            acArr = acArr.sort((a, b) => b.day - a.day).filter(e => e.day);
            info.aboutAircraft.push({
                title: '坐过机龄最老的飞机', main: acArr[0].code,
                main_mark: `${byAirlineCode[acArr[0].al]?.name || ''},${acArr[0].st},坐过${acArr[0].count}次,${acArr[0].type || '-'}`,
                sub: `首次交付已${(acArr[0].day / 365).toFixed(1)}年`, code: byAirlineCode[acArr[0].al]?.code
            });
            info.aboutAircraft.push({
                title: '坐过机龄最新的飞机', main: acArr[acArr.length - 1].code,
                main_mark: `${byAirlineCode[acArr[acArr.length - 1].al]?.name || ''},${acArr[acArr.length - 1].st},坐过${acArr[acArr.length - 1].count}次,${acArr[acArr.length - 1].type || '-'}`,
                sub: `交付已经${(acArr[acArr.length - 1].day)}天了`, code: byAirlineCode[acArr[acArr.length - 1]]?.code,
            });
            const daySum = flightDataWithAC.reduce((a, b) => [a[0] + b.ac.day, a[1] + (b.ac.day ? 1 : 0)], [0, 0]);
            const dayAverage = (daySum[0] / daySum[1] / 365).toFixed(2) + 'Ys'
            info.aboutAircraft.push({
                title: '坐过飞机的平均机龄', num: dayAverage,
                sub: `注:仅统计国内航司(含港澳台)`
            });
            let retiredAC = acArr.filter(e => e.st === '退出机队' || e.st === '变更注册' || e.st === '变更运营' || e.st === '撤销注册');
            const retiredCount = retiredAC.length;
            retiredAC = retiredAC.filter(e => e.day);
            info.aboutAircraft.push({
                title: '坐过的已告别的飞机', num: retiredCount,
                sub: !retiredAC.length ? '还是你资历不够...' : `机龄最小:${retiredAC[retiredAC.length - 1].code},交付${(retiredAC[retiredAC.length - 1].day / 365).toFixed(1)}年`
            });
            let destroyedAC = acArr.filter(e => e.st === '已报废');
            const destroyedCount = destroyedAC.length;
            destroyedAC = destroyedAC.filter(e => e.day);
            if (destroyedCount) {
                info.aboutAircraft.push({
                    title: '坐过的已报废的飞机', num: destroyedCount,
                    sub: `机龄最小:${destroyedAC[destroyedAC.length - 1].code},交付${(destroyedAC[destroyedAC.length - 1].day / 365).toFixed(1)}年`
                });
            }
            let byAirlines = {};
            acArr.forEach(e => {
                if (!byAirlines[e.al]) byAirlines[e.al] = {airline: e.al, sum: 0, count: 0}
                byAirlines[e.al].count += (e.day ? 1 : 0);
                byAirlines[e.al].sum += e.day;
            })
            const acAirlineDayRank = Object.values(byAirlines).map(e => ({
                airline: e.airline,
                value: e.sum / e.count
            })).sort((a, b) => b.value - a.value).filter(e => e.airline);
            if (acAirlineDayRank.length > 1) {
                info.aboutAircraft.push({
                    title: '坐过平均机龄最大的航司',
                    main: byAirlineCode[acAirlineDayRank[0].airline]?.name || acAirlineDayRank[0]?.airline,
                    code: byAirlineCode[acAirlineDayRank[0]?.airline]?.code,
                    main_mark: byAirlineCode[acAirlineDayRank[0].airline]?.en,
                    sub: `平均机龄${(acAirlineDayRank[0].value / 365).toFixed(2)}年`
                })
                info.aboutAircraft.push({
                    title: '坐过平均机龄最小的航司',
                    main: byAirlineCode[acAirlineDayRank[acAirlineDayRank.length - 1].airline]?.name || acAirlineDayRank[acAirlineDayRank.length - 1]?.airline,
                    code: byAirlineCode[acAirlineDayRank[acAirlineDayRank.length - 1]?.airline]?.code || '',
                    main_mark: byAirlineCode[acAirlineDayRank[acAirlineDayRank.length - 1].airline]?.en,
                    sub: `平均机龄${(acAirlineDayRank[acAirlineDayRank.length - 1].value / 365).toFixed(2)}年`
                })
            }
            const flightDataWithCoating = flightDataWithAC.filter(e => e.ac.ct);
            if (flightDataWithCoating.length) {
                info.aboutCoating = [];
                info.aboutCoating.push({
                    title: '乘坐彩绘机次数', num: flightDataWithCoating.length,
                    sub: '最近:' + (flightDataWithCoating[0] ? `${byAirlineCode[flightDataWithCoating[0].al]?.name || '其它航司'},${
                        flightDataWithCoating[0].ac.ct},${flightDataWithCoating[0].date || '未知日期'}` : '暂未乘坐')
                })
                let byCoating = {};
                flightDataWithCoating.forEach(e => {
                    if (!byCoating[e.ac.ct]) byCoating[e.ac.ct] = {...e.ac, count: 0}
                    byCoating[e.ac.ct].count++;
                })
                const coatingArr = Object.values(byCoating).sort((a, b) => b.count - a.count);
                info.aboutCoating.push({
                    title: '解锁的彩绘种数', num: coatingArr.length,
                    sub: `国内航司当前共录入263种`
                });
                info.aboutCoating.push({
                    title: '坐过最多的彩绘', main: coatingArr[0].ct, main_mark: byAirlineCode[coatingArr[0].al]?.name,
                    sub: `共坐过${coatingArr[0].count}次`, code: byAirlineCode[coatingArr[0].al]?.code
                });
                if (coatingArr.length > 1) {
                    info.aboutCoating.push({
                        title: '坐过第二多的彩绘', main: coatingArr[1].ct, main_mark: byAirlineCode[coatingArr[1].al]?.name,
                        sub: `共坐过${coatingArr[1].count}次`, code: byAirlineCode[coatingArr[1].al]?.code
                    });
                }
                if (coatingArr.length > 2) {
                    info.aboutCoating.push({
                        title: '坐过第三多的彩绘', main: coatingArr[2].ct, main_mark: byAirlineCode[coatingArr[2].al]?.name,
                        sub: `共坐过${coatingArr[2].count}次`, code: byAirlineCode[coatingArr[2].al]?.code
                    });
                }
                if (coatingArr.length > 3) {
                    info.aboutCoating.push({
                        title: '坐过第四多的彩绘', main: coatingArr[3].ct, main_mark: byAirlineCode[coatingArr[3].al]?.name,
                        sub: `共坐过${coatingArr[3].count}次`, code: byAirlineCode[coatingArr[3].al]?.code
                    });
                }
                if (coatingArr.length > 4) {
                    info.aboutCoating.push({
                        title: '坐过第五多的彩绘', main: coatingArr[4].ct, main_mark: byAirlineCode[coatingArr[4].al]?.name,
                        sub: `共坐过${coatingArr[4].count}次`, code: byAirlineCode[coatingArr[4].al]?.code
                    });
                }
                if (coatingArr.length > 5) {
                    info.aboutCoating.push({
                        title: '坐过第六多的彩绘', main: coatingArr[5].ct, main_mark: byAirlineCode[coatingArr[5].al]?.name,
                        sub: `共坐过${coatingArr[5].count}次`, code: byAirlineCode[coatingArr[5].al]?.code
                    });
                }
            }
        }
        // console.log(info);
        setInfo(info);
    }, [props.data]);
    useEffect(() => {
        const handleTouchMove = (event) => event.preventDefault();
        document.addEventListener('touchmove', handleTouchMove, {passive: false});
        return () => {
            document.removeEventListener('touchmove', handleTouchMove, {passive: false});
        }
    }, []);
    if (!info) return null;
    return (
        <div className={"detail-layer " + (layerClosing ? 'closing' : '')} onAnimationEnd={onLayerAnimEnd}>
            <div className="detail-container">
                <div className="detail-container-inner">
                    <SwiperBox
                        key="Latlng"
                        items={info[positionMost] || []}
                        width={100}
                        height={70}
                        headerWidth={160}
                        header={
                            <div>
                                <img src={LatlngImg} className="lat-lng-img"/>
                                <div className="lat-lng-choice">
                                    <div className={positionMost === 'eastmost' ? 'selected' : ''}
                                         onClick={() => setPositionMost('eastmost')}>东
                                    </div>
                                    <div className={positionMost === 'southmost' ? 'selected' : ''}
                                         onClick={() => setPositionMost('southmost')}>南
                                    </div>
                                    <div className={positionMost === 'westmost' ? 'selected' : ''}
                                         onClick={() => setPositionMost('westmost')}>西
                                    </div>
                                    <div className={positionMost === 'northmost' ? 'selected' : ''}
                                         onClick={() => setPositionMost('northmost')}>北
                                    </div>
                                </div>
                            </div>
                        }
                        render={(item, index) => <div onClick={() => handleAirportClick(item)}
                                                      className="normal-box thinner-box route-box">
                            <div className="cover-to-top">
                                <div
                                    className={"medium-title one-line text-shadow-more medium-title-" + (item.name.length)}>{item.name}</div>
                                <div className="very-small-text one-line text-shadow-more margin-top-2 num">
                                    {item.country === '中国' ? (item.province || '') : (item.country || '')} / {item.en || ''}
                                </div>
                                {positionMost === 'eastmost' || positionMost === 'westmost' ?
                                    <div className="very-small-text one-line text-shadow-more margin-top-15">
                                        {item.lng > 0 ? '东' : '西'}经{Math.abs(item.lng || 0).toFixed(2)}°
                                    </div> :
                                    <div className="very-small-text one-line text-shadow-more margin-top-15">
                                        {item.lat > 0 ? '北' : '南'}纬{Math.abs(item.lat || 0).toFixed(2)}°
                                    </div>
                                }
                            </div>
                            <div className="airport-bg airport-bg-complete"
                                 style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${item.id}.jpg-220')`}}></div>
                            <div className="rank-right num rank-right-bottom">{index + 1}</div>
                            <div className="rotate-three-code num">{item.code}</div>
                        </div>}
                        renderKey={e => e.code}
                    />
                    <SwiperBox
                        key="topRoutes"
                        items={info.topRoutes}
                        width={120}
                        height={120}
                        headerWidth={80}
                        header={<img src={TopRouteImg}/>}
                        render={(item, index) => <div className="normal-box route-box">
                            <div onClick={() => handleAirportClick(item.dep)}
                                 className={"medium-title one-line text-shadow medium-title-" + (item.dep?.name?.length)}>
                                {item.dep?.name}</div>
                            <div className="very-small-text one-line text-shadow num">
                                {item.dep.country === '中国' ? (item.dep.province || '') : (item.dep.country || '')} / {item.dep.en || ''}
                            </div>
                            <div onClick={() => handleAirportClick(item.arr)}
                                 className={"medium-title one-line margin-top-5 text-shadow medium-title-" + (item.arr?.name?.length)}>
                                {item.arr?.name}</div>
                            <div className="very-small-text one-line text-shadow num">
                                {item.arr.country === '中国' ? (item.arr.province || '') : (item.arr.country || '')} / {item.arr.en || ''}
                            </div>
                            {item.stop ? <div
                                onClick={() => handleAirportClick(item.stop)}
                                className="very-small-text margin-top-2 one-line text-shadow">经停{item.stop?.name}</div> : null}
                            <div
                                className="box-footer-label box-footer text-shadow">{item.count}次, {(item.dis || 0).toFixed(0)}km
                            </div>
                            <div className="rank-right num">{index + 1}</div>
                        </div>}
                        renderKey={e => (e.dep?.code) + '-' + (e.arr?.code)}
                    />
                    <SwiperBox
                        key="topAirports"
                        items={info.topAirports[topAirportsMode]}
                        width={100}
                        height={140}
                        headerWidth={120}
                        header={
                            <div>
                                <img src={TopAirportImg} className="lat-lng-img"/>
                                <div className="box-footer-label box-footer header-item num box-footer-label-left"
                                     key={1}>{info.airportCount || 0} / 5552
                                </div>
                                <div className="top-airport-mode-choice">
                                    <div className={topAirportsMode === 'all' ? 'selected' : ''}
                                         onClick={() => setTopAirportsMode('all')}>全部
                                    </div>
                                    <div className={topAirportsMode === 'domestic' ? 'selected' : ''}
                                         onClick={() => info.topAirports['domestic'].length && setTopAirportsMode('domestic')}>国内
                                    </div>
                                    <div className={topAirportsMode === 'intl' ? 'selected' : ''}
                                         onClick={() => info.topAirports['intl'].length && setTopAirportsMode('intl')}>国际
                                    </div>
                                </div>
                            </div>
                        }
                        render={(item, index) => <div className="normal-box thinner-box route-box"
                                                      onClick={() => handleAirportClick(item)}>
                            <div className="cover-to-top">
                                <div
                                    className={"medium-title one-line text-shadow-more medium-title-" + (item.name.length)}>{item.name}</div>
                                <div className="very-small-text one-line text-shadow-more margin-top-2 num">
                                    {item.country === '中国' ? (item.province || '') : (item.country || '')} / {item.en || ''}
                                </div>
                                <div className="very-small-text one-line text-shadow margin-top-15">
                                    {Math.abs(item.lng || 0).toFixed(1)}° {item.lng > 0 ? 'E' : 'W'},
                                    {Math.abs(item.lat || 0).toFixed(1)}° {item.lat > 0 ? 'N' : 'S'}
                                </div>
                                <div className="very-small-text one-line text-shadow margin-top-15">
                                    共飞{item.count || 0}次，常去
                                </div>
                                {item.topDest.map((dest, index) => (
                                    <div className="very-small-text one-line text-shadow margin-top-2" key={index}>
                                        ※ {dest?.dist?.name}({dest?.count})
                                    </div>))}
                            </div>
                            <div className="airport-bg"
                                 style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${item.id}.jpg-220')`}}></div>
                            <div className="rank-right num rank-right-bottom">{index + 1}</div>
                            <div className="rotate-three-code num">{item.code}</div>
                        </div>}
                        renderKey={e => e.code}
                    />
                    {info.topCountries ?
                        <SwiperBox
                            key="topCountries"
                            items={info.topCountries}
                            width={120}
                            height={72}
                            headerWidth={80}
                            header={
                                [<img src={TopCountryImg} key={0}/>,
                                    <div className="box-footer-label box-footer header-item num box-footer-label-left"
                                         key={1}>{info.countryCount || 0} / 225</div>]
                            }
                            render={(item, index) => <div className="normal-box route-box">
                                <div
                                    className={"medium-title one-line text-shadow medium-title-" + (item.name.length)}>{item.name}</div>
                                <div className="very-small-text one-line text-shadow margin-top-2 num">
                                    飞行{item.count}次
                                </div>
                                <div className="very-small-text two-line margin-top-5">
                                    {item.topAirports?.map(e => `${e.name}(${e.count})`)?.join(',')}
                                </div>
                                <div className="rank-right num rank-right-bottom">{index + 1}</div>
                            </div>}
                            renderKey={e => e.name}
                        />
                        : null}
                    {info.topProvinces ?
                    <SwiperBox
                        key="topProvinces"
                        items={info.topProvinces}
                        width={100}
                        height={72}
                        headerWidth={80}
                        header={
                            [<img src={TopProvinceImg} key={0}/>,
                                <div className="box-footer-label box-footer header-item num box-footer-label-left"
                                     key={1}>{info.provinceCount || 0} / 34</div>]
                        }
                        render={(item, index) => <div className="normal-box thinner-box route-box">
                            <div
                                className={"medium-title one-line text-shadow medium-title-" + (item.name.length)}>{item.name}</div>
                            <div className="very-small-text one-line text-shadow margin-top-2 num">
                                飞行{item.count}次
                            </div>
                            <div className="very-small-text two-line margin-top-5">
                                {item.topAirports?.map(e => `${e.name}(${e.count})`)?.join(',')}
                            </div>
                            <div className="rank-right num rank-right-bottom">{index + 1}</div>
                        </div>}
                        renderKey={e => e.name}
                    /> : null}
                    <SwiperBox
                        key="topAirlines"
                        items={info.topAirlines[topAirlinesMode]}
                        width={topAirlinesMode === 'domestic' ? 105 : 135}
                        height={72}
                        headerWidth={120}
                        header={
                            <div>
                                <img src={TopAirlineImg} className="lat-lng-img"/>
                                <div className="top-airport-mode-choice short">
                                    <div className={topAirlinesMode === 'all' ? 'selected' : ''}
                                         onClick={() => setTopAirlinesMode('all')}>全部
                                    </div>
                                    <div className={topAirlinesMode === 'domestic' ? 'selected' : ''}
                                         onClick={() => info.topAirlines['domestic'].length && setTopAirlinesMode('domestic')}>国内
                                    </div>
                                    <div className={topAirlinesMode === 'intl' ? 'selected' : ''}
                                         onClick={() => info.topAirlines['intl'].length && setTopAirlinesMode('intl')}>国际
                                    </div>
                                </div>
                            </div>
                        }
                        render={(item, index) => <div className="normal-box route-box">
                            <div style={{width: 'fit-content'}}
                                 className={"medium-title one-line text-shadow"}>{item.name}
                                {topAirlinesMode === 'domestic' ? null : <small>{item.country}</small>}
                            </div>
                            <div
                                className={"very-small-text one-line text-shadow num " + ((topAirlinesMode === 'domestic' ? 'margin-top-2' : ''))}>
                                飞行{item.count || 0}次 / {item.en || ''}
                            </div>
                            <div className="very-small-text two-line margin-top-2">
                                最长{(item.longest?.dis || 0).toFixed(0)}km,从{item.longest?.dep?.name || '-'}到{item.longest?.arr?.name || '-'},
                                {item.longest?.date || ''}
                            </div>
                            <div className="rank-right num rank-right-bottom smaller">{index + 1}</div>
                            <div className="rotate-airline num">{item.code}</div>
                        </div>}
                        renderKey={e => e.name}
                    />
                    <SwiperBox
                        key="longestFlights"
                        items={info.longestFlights}
                        width={120}
                        height={120}
                        headerWidth={80}
                        header={<img src={LongestImg}/>}
                        render={(item, index) => <div className="normal-box route-box">
                            <div onClick={() => handleAirportClick(item.dep)}
                                 className={"medium-title one-line text-shadow medium-title-" + (item.dep?.name?.length)}>
                                {item.dep?.name}</div>
                            <div className="very-small-text one-line text-shadow num">
                                {item.dep.country === '中国' ? (item.dep.province || '') : (item.dep.country || '')} / {item.dep.en || ''}
                            </div>
                            <div onClick={() => handleAirportClick(item.arr)}
                                 className={"medium-title one-line margin-top-5 text-shadow medium-title-" + (item.arr?.name?.length)}>
                                {item.arr?.name}</div>
                            <div className="very-small-text one-line text-shadow num">
                                {item.arr.country === '中国' ? (item.arr.province || '') : (item.arr.country || '')} / {item.arr.en || ''}
                            </div>
                            {item.stop ? <div onClick={() => handleAirportClick(item.stop)}
                                              className="very-small-text margin-top-2 one-line text-shadow">经停{item.stop?.name}</div> : null}
                            <div
                                className="box-footer-label box-footer text-shadow">{(item.dis || 0).toFixed(0)}km, {item.count}次
                            </div>
                            <div className="rank-right num">{index + 1}</div>
                        </div>}
                        renderKey={e => (e.dep?.code) + '-' + (e.arr?.code) + '-' + (e.stop?.code)}
                    />
                    <SwiperBox
                        key="shortestFlights"
                        items={info.shortestFlights}
                        width={120}
                        height={120}
                        headerWidth={80}
                        header={<img src={ShortestImg}/>}
                        render={(item, index) => <div className="normal-box route-box">
                            <div onClick={() => handleAirportClick(item.dep)}
                                 className={"medium-title one-line text-shadow medium-title-" + (item.dep?.name?.length)}>
                                {item.dep?.name}</div>
                            <div className="very-small-text one-line text-shadow num">
                                {item.dep.country === '中国' ? (item.dep.province || '') : (item.dep.country || '')} / {item.dep.en || ''}
                            </div>
                            <div onClick={() => handleAirportClick(item.arr)}
                                 className={"medium-title one-line margin-top-5 text-shadow medium-title-" + (item.arr?.name?.length)}>
                                {item.arr?.name}</div>
                            <div className="very-small-text one-line text-shadow num">
                                {item.arr.country === '中国' ? (item.arr.province || '') : (item.arr.country || '')} / {item.arr.en || ''}
                            </div>
                            {item.stop ? <div onClick={() => handleAirportClick(item.stop)}
                                              className="very-small-text margin-top-2 one-line text-shadow">经停{item.stop?.name}</div> : null}
                            <div
                                className="box-footer-label box-footer text-shadow">{(item.dis || 0).toFixed(0)}km, {item.count}次
                            </div>
                            <div className="rank-right num">{index + 1}</div>
                        </div>}
                        renderKey={e => (e.dep?.code) + '-' + (e.arr?.code) + '-' + (e.stop?.code)}
                    />
                    <SwiperBox
                        key="closedAirports"
                        items={info.closedAirports}
                        width={105}
                        height={70}
                        headerWidth={80}
                        header={
                            <img src={MemoryImg}/>
                        }
                        render={(item, index) => <div className="normal-box thinner-box route-box">
                            <div className="cover-to-top">
                                <div
                                    className={"medium-title one-line text-shadow-more medium-title-" + (item.name.length)}>{item.name}</div>
                                <div className="very-small-text one-line text-shadow-more margin-top-2 num">
                                    {item.country === '中国' ? (item.province || '') : (item.country || '')} / {item.en || ''}
                                </div>
                                {item.lastTime ?
                                    <div className="very-small-text one-line text-shadow-more margin-top-15 num">
                                        最后留念 {item.lastTime}
                                    </div> : null}
                            </div>
                            <div className="airport-bg airport-bg-complete"
                                 style={{backgroundImage: `url('https://cdn.starkdu.com/cities/${item.id}.jpg-220')`}}></div>
                            <div className="rank-right num rank-right-bottom">{index + 1}</div>
                            <div className="rotate-three-code num">{item.code}</div>
                        </div>}
                        renderKey={e => e.code}
                    />
                    <SwiperBox
                        key="aboutClass"
                        items={info.aboutClass}
                        width={130}
                        height={78}
                        headerWidth={80}
                        header={<img src={AboutClassImg}/>}
                        render={(item, index) => <div className="normal-box thinner-box route-box">
                            <div className="very-small-text one-line text-shadow-more num">{item.title}</div>
                            {item.main ? <div
                                className={"medium-title one-line text-shadow-more margin-top-5 num"}>{item.main}</div> : null}
                            {item.main_mark ? <div
                                className="very-small-text one-line text-shadow-more num">{item.main_mark}</div> : null}
                            {item.num !== undefined ? <div
                                className={"large-title one-line text-shadow-more margin-top-5 num"}>{item.num}</div> : null}
                            <div className="very-small-text one-line margin-top-5 num">{item.sub}</div>
                            {item.num !== undefined ? <div
                                className="rank-right num rank-right-bottom">{typeof item.num === 'string' ? item.num.replace(/\D/, '') : item.num}</div> : null}
                            {item.code ? <div className="rank-right num rank-right-bottom">{item.code}</div> : null}
                        </div>}
                        renderKey={e => e.title}
                    />
                    <SwiperBox
                        key="aboutType"
                        items={info.aboutType}
                        width={150}
                        height={78}
                        headerWidth={80}
                        header={<img src={AboutTypeImg}/>}
                        render={(item, index) => <div className="normal-box thinner-box route-box">
                            <div className="very-small-text one-line text-shadow-more num">{item.title}</div>
                            {item.main ? <div
                                className={"medium-title one-line text-shadow-more margin-top-5 num"}>{item.main}</div> : null}
                            {item.main_mark ? <div
                                className="very-small-text one-line text-shadow-more num">{item.main_mark}</div> : null}
                            {item.num !== undefined ? <div
                                className={"large-title one-line text-shadow-more margin-top-5 num"}>{item.num}</div> : null}
                            <div className="very-small-text one-line margin-top-5 num">{item.sub}</div>
                            {item.num !== undefined ? <div
                                className="rank-right num rank-right-bottom">{typeof item.num === 'string' ? item.num.replace(/\D/, '') : item.num}</div> : null}
                            {item.code ? <div className="rank-right num rank-right-bottom">{item.code}</div> : null}
                        </div>}
                        renderKey={e => e.title}
                    />
                    <SwiperBox
                        key="aboutAircraft"
                        items={info.aboutAircraft}
                        width={140}
                        height={78}
                        headerWidth={80}
                        header={<img src={AboutACImg}/>}
                        render={(item, index) => <div className="normal-box thinner-box route-box">
                            <div className="very-small-text one-line text-shadow-more num">{item.title}</div>
                            {item.main ? <div
                                className={"medium-title one-line text-shadow-more margin-top-5 num"}>{item.main}</div> : null}
                            {item.main_mark ? <div
                                className="very-small-text one-line text-shadow-more num">{item.main_mark}</div> : null}
                            {item.num !== undefined ? <div
                                className={"large-title one-line text-shadow-more margin-top-5 num"}>{item.num}</div> : null}
                            <div className="very-small-text one-line margin-top-5 num">{item.sub}</div>
                            {item.num !== undefined ? <div
                                className="rank-right num rank-right-bottom">{typeof item.num === 'string' ? item.num.replace(/\D/, '') : item.num}</div> : null}
                            {item.code ? <div className="rank-right num rank-right-bottom">{item.code}</div> : null}
                        </div>}
                        renderKey={e => e.title}
                    />
                    <SwiperBox
                        key="aboutCoating"
                        items={info.aboutCoating}
                        width={132}
                        height={78}
                        headerWidth={80}
                        header={<img src={AboutCoatingImg}/>}
                        render={(item, index) => <div className="normal-box thinner-box route-box">
                            <div className="very-small-text one-line text-shadow-more num">{item.title}</div>
                            {item.main ? <div
                                className={"medium-title one-line text-shadow-more margin-top-5 num"}>{item.main}</div> : null}
                            {item.main_mark ? <div
                                className="very-small-text one-line text-shadow-more num">{item.main_mark}</div> : null}
                            {item.num !== undefined ? <div
                                className={"large-title one-line text-shadow-more margin-top-5 num"}>{item.num}</div> : null}
                            <div className="very-small-text one-line margin-top-5 num">{item.sub}</div>
                            {item.num !== undefined ? <div
                                className="rank-right num rank-right-bottom">{typeof item.num === 'string' ? item.num.replace(/\D/, '') : item.num}</div> : null}
                            {item.code ? <div className="rank-right num rank-right-bottom">{item.code}</div> : null}
                        </div>}
                        renderKey={e => e.title}
                    />
                </div>
            </div>
            <div className="close-btn" onClick={handleClose}>收起</div>
            <div className="detail-title num">{props.data?.historyMode ? '所有飞行记录' : (props.data?.year || '')}</div>
        </div>
    );
}

export default Detail;
