import React from 'react';
import './index.css';
function Loading(props) {
    const { loading } = props;
    return <div className="loader" style={loading ? {opacity:1} : {}}>
        <div className="la-ball-atom la-2x">
            <div></div>
            {/*<div></div>*/}
            {/*<div></div>*/}
            {/*<div></div>*/}
        </div>
    </div>
}
export default Loading